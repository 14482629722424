import Logo from "../assets/images/logo.png";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios";
import { loginEndPoint } from "../services/api_endpoints";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";

export default function Login() {
    const [errorMessage, setErrorMessage] = useState("");

    const schema = yup
        .object({
            email: yup.string().required(),
            password: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })
    const onSubmit = (data) => {
        setErrorMessage('');
        axios.post(loginEndPoint, data)
        .then((res) => {
            
            if(res.data.status == 401) {
                setErrorMessage(res.data.message);
            }
            toast.success('')
            localStorage.setItem("login_details", JSON.stringify(res.data));
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("login", JSON.stringify(true));
            window.location.reload()
        }).catch((err) => {
            localStorage.removeItem("login_details");
            localStorage.removeItem("token");
            localStorage.setItem("login", JSON.stringify(false));
        })
    }
    
    return (
        <div className="flex h-screen w-screen items-center justify-center bg-primary bg-opacity-[0.3]">

            <div className="bg-white shadow-xl rounded-lg py-[10px] w-[300px] sm:w-[400px] p-[15px]">
                <div className="">

                    <div className="w-[150px] mx-auto">
                        <img src={Logo} width="100%" />
                    </div>

                    <div className="w-fit mx-auto">
                        <h1 className="mt-[10px] text-[18px] font-[500] text-gray-500 text-center">Welcome Back</h1>
                        <p className="m-[10px] text-[18px] font-[500] text-primary text-center">Sign in to continue</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="w-[270px] mx-auto">

                        <div>
                            <p className="text-[14px] mb-[5px] text-primary">Email address</p>
                            <input {...register("email")} name="email" type="email" placeholder="Enter your email" className="border rounded p-[7px] w-full text-[14px]" />
                            <p className="text-[12px] text-red-600">{errors.email?.message}</p>
                        </div>
                        <div className="mt-[15px]">
                            <p className="text-[14px] mb-[5px] text-primary">Password</p>
                            <input {...register("password")} name="password" type="password" placeholder="Enter your password" className="border rounded p-[7px] w-full text-[14px]" />
                            <p className="text-[12px] text-red-600">{errors.password?.message}</p>
                        </div>

                        {/* <div className="m-[5px] text-end">
                            <p className="text-primary text-[14px] text-opacity-[0.7]">Forgot password?</p>
                        </div> */}
                        <p className="text-[12px] text-red-600 mt-[10px]">{errorMessage}</p>

                        <button type="submit" className="my-[20px] bg-primary text-white text-[14px] w-full rounded p-[5px]">Sign in</button>
                    </form>
                </div>
            </div>
        </div>
    );
}