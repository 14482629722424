import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./header";
import SideBar from "./sidebar";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

export default function Layout({
    userName
}) {

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const [isMenuOpen, setIsMenuOpen] = useState(true);

    const [currentLocation, setCurrentLocation] = useState("")
    const [currentMenu, setCurrentMenu] = useState("")

    useEffect(() => {
        setCurrentLocation(pathname)
        setCurrentMenu(pathname.split("/")[pathname.split("/").length - 1]);
        setIsMenuOpen(window.innerWidth > 768);
        document.body.classList.toggle("no-scroll", isMenuOpen && window.innerWidth <= 768);

    }, [pathname])

    return (
        <div className="flex w-full bg-[#F3F3F3]">
             {isMenuOpen && window.innerWidth <= 768 && (
                <div className="backdrop-overlay" onClick={() => setIsMenuOpen(false)} />
            )}

            {/* ${isMenuOpen ? "translate-x-0" : "-translate-x-[100%]"} */}
            <div className={` ${isMenuOpen ? "translate-x-0" : "-translate-x-[250px] md:translate-x-0"} transition ease-in-out duration-[300ms] w-[250px] fixed top-[65px] bg-white md:static z-[1]`}>
                <SideBar />
            </div>
            <div className={`${isMenuOpen ? "w-full md:w-[calc(100vw-250px)]" : "w-[100vw]"} transition ease-in-out duration-[300ms] h-screen overflow-y-scroll`}>

                <Header menuOption={isMenuOpen} setMenuOption={setIsMenuOpen} userName={userName} />

                <div className="flex justify-between p-[5px] sm:p-[20px] bg-white sticky top-0 z-[1]">
                    <p className="capitalize text-primary font-[500] text-[14px] sm:text-[18px]">{currentMenu.length == 0 ? "Dashboard" : currentMenu}</p>
                    <div className="flex gap-3 items-center">
                        <span onClick={(e) => {
                            e.preventDefault();
                            navigate("/")
                        }} className="i-lets-icons-home-duotone w-[15px] sm:w-[30px] h-[15px] sm:h-[30px]"></span>
                        <p className="mt-[5px] text-[12px] sm:text-[16px] capitalize">{currentLocation}</p>
                    </div>
                </div>

                <div className="p-[10px] sm:p-[20px]">
                    <Outlet />
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                
                />
        </div>
    );
}