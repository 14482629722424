import { useEffect,useState } from "react";
import { taskPostEndPoint ,taskResponseEndPoint} from "../../../services/api_endpoints";
import { postAxiosWithToken ,getAxiosWithToken} from "../../../services/axios_service";

const TaskAddModal = ({ onClose, id }) => {
  const [formData, setFormData] = useState({
    link_1: "",
    link_2: "",
    link_3: "",
    link_4: "",
    student_comments: ""
  });

  useEffect(() => {
    console.log("id init");
    console.log(id);
    if (id) {
      handleTaskView(id);
    }

  
},[])

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.link_1) {
        setErrors({ link_1: "Link 1 is required" });
        return;
      }
    setErrors({});
    const data = {
      session_details: id,
      link_1: formData.link_1 || null,
      link_2: formData.link_2 || null,
      link_3: formData.link_3 || null,
      link_4: formData.link_4 || null,
      student_comments: formData.student_comments || null
    };
    try {
      const response = await postAxiosWithToken({
        url: taskPostEndPoint,
        body: data
      });
  
      if (response && response.status) {
        console.log("Response status:", response.status);
        console.log("Task submitted successfully.");
        onClose(); // Close the modal on success
      } else {
        console.error("Unexpected response format:", response);
        setErrors({ api: "Failed to submit the task. Please try again." });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      
    }
  };

  const handleTaskView = async (id) => {
    console.log(id);
    try {
      const response = await getAxiosWithToken({
        url: taskResponseEndPoint+id+"/",
        // body: data
      });
  
      if (response && response.status) {
        console.log("Response status:", response.status);
        setFormData(response.data)
        // console.log(response.data);
        // onClose(); // Close the modal on success
      } else {
        console.error("Unexpected response format:", response);
        setErrors({ api: "Failed to submit the task. Please try again." });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      
    }
   

  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md w-[90%] max-w-md">
        <h2 className="text-[20px] font-bold mb-4">Post Task</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Link 1</label>
            <input
              type="url"
              name="link1"
              value={formData.link_1}
              onChange={handleChange}
              className="w-full border p-2 rounded"
              placeholder="Enter Link 1"
            />
            {errors.link_1 && <p className="text-red-500 text-sm">{errors.link_1}</p>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Link 2</label>
            <input
              type="url"
              name="link2"
              value={formData.link_2}
              onChange={handleChange}
              className="w-full border p-2 rounded"
              placeholder="Enter Link 2"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Link 3</label>
            <input
              type="url"
              name="link3"
              value={formData.link_3}
              onChange={handleChange}
              className="w-full border p-2 rounded"
              placeholder="Enter Link 3"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Link 4</label>
            <input
              type="url"
              name="link4"
              value={formData.link_4}
              onChange={handleChange}
              className="w-full border p-2 rounded"
              placeholder="Enter Link 4"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Comment</label>
            <textarea
              name="comment"
              value={formData.student_comments}
              onChange={handleChange}
              className="w-full border p-2 rounded"
              placeholder="Enter your comments"
            />
          </div>

          {errors.api && <p className="text-red-500 text-sm">{errors.api}</p>}

          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
            <button type="submit" className="bg-secondary text-white px-4 py-2 rounded">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TaskAddModal;
