import { useEffect, useState } from "react";
import { studentsEndPoint, studentsListEndPoint } from "../../../services/api_endpoints";
import Popup from "../../../components/Popup";
import { ToastContainer, toast } from "react-toastify";
import { deleteAxiosWithToken, postAxiosWithToken } from "../../../services/axios_service";
import Pagination from "../../../components/Pagination";
import StudentsTableFilter from "./student_table_filter";
import StudentForm from "./student_form";
import ChangeStudentPassword from "./change_student_password";
import { getDateInText } from "../../../utilities/datetime_utils";

export default function Students() {

    const [isAddPopUpOpen, setIsAddPopUpOpen] = useState(false)
    const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false)
    const [isChangePasswordPopUpOpen, setIsChangePasswordPopUpOpen] = useState(false)

    const [studentsList, setStudentList] = useState([]);
    const [formMode, setFormMode] = useState("create")
    const [currentItem, setCurrentItem] = useState({});

    const [totalPages, setTotalPages] = useState(1);
    const [tableFilters, setTableFilters] = useState({
        search: "",
        page: 1,
        itemsperpage: 10
    })

    useEffect(() => {
        getStudentsList()
    }, [tableFilters])

    const getStudentsList = async () => {
        let response = await postAxiosWithToken({
            url: studentsListEndPoint,
            body: tableFilters
        });

        if (response != null) {
            setStudentList(response.data.list)
            setTotalPages(response.data.total_pages)
        }
    }

    const deleteStudent = async () => {

        let response = await deleteAxiosWithToken({
            url: studentsEndPoint + currentItem.id + "/"
        })

        if (response != null) {
            toast.success(response.message)
            getStudentsList()
            setFormMode("create")
            setIsDeletePopUpOpen(false)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            itemsperpage: value,
            page: 1,
        }));
    };
    return (
        <div className="w-full bg-white rounded-lg  p-[15px]">
            <div className=" flex justify-between items-center mb-[10px]">
                <p className="text-[12px] sm:text-[16px] sm:tracking-[1px]">Manage Students</p>
                <button onClick={(e) => {
                    e.preventDefault();
                    setIsAddPopUpOpen(true);
                }} className="bg-primary text-white text-[12px] sm:text-[16px] w-[75px] sm:w-[100px] h-[30px] sm:h-[40px] rounded font-[500]">Add</button>
            </div>

            <div className="mb-[5px]">
                <StudentsTableFilter filters={tableFilters} setFilters={setTableFilters} />
            </div>
            <div className=" overflow-scroll ">
                <table className="w-full">
                    <thead className="bg-primary text-white text-[12px] sm:text-[16px] ">
                        <tr>
                            <th className="p-[10px] text-left">S No</th>
                            <th className="p-[10px] text-left">Student Name</th>
                            <th className="p-[10px] text-left">Email</th>
                            <th className="p-[10px] text-left">Mobile</th>
                            <th className="p-[10px] text-left">Created At</th>
                            <th className="p-[10px] text-left">Action</th>
                        </tr>
                    </thead>
                    <tbody className="text-[12px] sm:text-[16px] ">
                        {
                            studentsList.map((item, index) => (
                                <tr className={`${index % 2 == 0 ? "" : "bg-gray-100"}`} key={"student" + index}>
                                    <td className="px-[10px] py-[5px] text-left">{(tableFilters.page - 1) * tableFilters.itemsperpage + index + 1}</td>
                                    <td className="px-[10px] py-[5px] text-left">
                                    <div className="flex gap-3 items-center">
                                        <div className="w-[50px] h-[50px] border rounded-full bg-gray-300 overflow-hidden item-center flex justify-center">
                                            <img src={item.profile_image} className="w-full h-full" />
                                        </div>
                                        <p className="capitalize">{item.student_name}</p>
                                        </div>
                                    </td>
                                    <td className="px-[10px] py-[5px] text-left">{item.email}</td>
                                    <td className="px-[10px] py-[5px] text-left">{item.phone_number}</td>
                                    <td className="px-[10px] py-[5px] text-left">{getDateInText(item.created_at)}</td>
                                    <td className="px-[10px] py-[5px] text-left">
                                        <div className="flex gap-3">
                                        <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentItem(item);
                                                    setIsChangePasswordPopUpOpen(true);
                                                }}
                                                className="rounded w-[35px] h-[35px] text-black flex items-center justify-center"><span className="i-mdi-password-reset w-[25px] h-[25px]"></span></button>
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentItem(item);
                                                    setIsAddPopUpOpen(true);
                                                    setFormMode("update")
                                                }}
                                                className="rounded w-[35px] h-[35px] text-black flex items-center justify-center"><span className="i-mingcute-edit-4-line w-[25px] h-[25px]"></span></button>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                setIsDeletePopUpOpen(true);
                                                setCurrentItem(item);
                                            }} className="rounded w-[35px] h-[35px] text-black flex items-center justify-center"><span className="i-mingcute-delete-2-line w-[25px] h-[25px]"></span></button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>


            </div>
            <div className="mt-[5px]">
                <Pagination totalPages={totalPages} currentPage={tableFilters.page} setCurrentPage={pageHandler} entries={tableFilters.itemsperpage} entriesHandler={entriesHandler} />
            </div>

            <Popup isOpen={isAddPopUpOpen} setIsOpen={() => {
                setIsAddPopUpOpen(false);
                setFormMode("create")
            }}>
                <div className="pb-[10px] border-b w-full flex justify-between items-center" >
                    <p >Student Form</p>
                    <button onClick={(e) => {
                        setIsAddPopUpOpen(false);
                        setFormMode("create")
                    }}>
                        <span className="i-mingcute-close-line w-[20px] h-[20px]"></span>
                    </button>
                </div>
                <StudentForm setFormMode={setFormMode} formMode={formMode} fetchData={getStudentsList} setPopUpOpen={setIsAddPopUpOpen} currentItem={currentItem} />
            </Popup>

            <Popup isOpen={isDeletePopUpOpen} setIsOpen={() => {
                setIsDeletePopUpOpen(false);
            }}>
                <div className="w-[250px] sm:w-[300px]">
                    <div className="pb-[10px] border-b w-full flex justify-between items-center" >
                        <p >Student Delete</p>
                        <button onClick={(e) => {
                            e.preventDefault();
                            setIsDeletePopUpOpen(false);
                            setFormMode("create")
                        }}>
                            <span className="i-mingcute-close-line w-[20px] h-[20px]"></span>
                        </button>
                    </div>
                    <p className="text-gray-500 my-[20px]">Are you sure want to delete?</p>
                    <div className="flex justify-between items-center gap-3">
                        <button onClick={(e) => {
                            e.preventDefault();
                            setIsDeletePopUpOpen(false)
                        }} className="my-[20px] bg-gray-200 border text-secondary text-[14px] w-full rounded p-[5px]">No</button>
                        <button onClick={(e) => {
                            e.preventDefault();
                            deleteStudent()
                        }} className="my-[20px] bg-primary text-white text-[14px] w-full rounded p-[5px]">Yes</button>
                        {/* <ToastContainer /> */}
                    </div>
                </div>
            </Popup>

            <Popup isOpen={isChangePasswordPopUpOpen} setIsOpen={() => {
                setIsChangePasswordPopUpOpen(false);
            }}>
                  <div className="pb-[10px] border-b w-full flex justify-between items-center" >
                    <p >Change Student Password</p>
                    <button onClick={(e) => {
                        setIsChangePasswordPopUpOpen(false);
                    }}>
                        <span onClick={(e) => { e.preventDefault();
                    setIsChangePasswordPopUpOpen(false);}} className="i-mingcute-close-line w-[20px] h-[20px]"></span>
                    </button>
                    </div>
                <ChangeStudentPassword setPopUpOpen={setIsChangePasswordPopUpOpen} currentItem={currentItem} />
            </Popup>
        </div>
    );
}