import { courseListEndPoint, s3ImageUploadEndPoint, studentsListEndPoint, batchesEndPoint } from "../../../services/api_endpoints";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {ToastContainer, toast } from "react-toastify";
import { getAxiosWithToken, postAxiosWithToken, putAxiosWithToken } from "../../../services/axios_service";
import { useEffect, useState } from "react";
import SelectedStudentsTable from "./selected_students_table";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SearchSelect from "../../../components/SearchSelect";

export default function BatchesForm() {

    const navigate = useNavigate();

    const { id } = useParams()

    const [formMode, setFormMode] = useState("create");

    const [courseList, setCourseList] = useState([]);
    const [studentsList, setStudentsList] = useState([]);

    const [selectedCourse, setSelectedCourse] = useState("");
    const [selectedStudentsList, setSelectedStudentsList] = useState([]);
    const [addedStudents, setAddedStudents] = useState([]);

    const [openDropdown, setOpenDropdown] = useState(false)

    const schema = yup
        .object({
            course_details: yup.string().required(),
            start_date: yup.string().required(),
            end_date: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
    })

    useEffect(() => {

        if (id) {
            setFormMode("update")
            getBatchDetails()
        }

        getCourseList();
        getStudentsList();
    },[])

    const getBatchDetails = async () => {
        let response = await getAxiosWithToken({
            url: batchesEndPoint + id +"/"
        })

        if (response != null) {
            setValue("course_details", response.data.course_details)
            setValue("start_date", response.data.start_date)
            setValue("end_date", response.data.end_date)

            setSelectedCourse(response.data.course_details)

            setSelectedStudentsList(response.data.students)
        }
    }

    const getCourseList = async () => {
        let response = await getAxiosWithToken({
            url: courseListEndPoint
        })

        if (response != null) {
            setCourseList(response.data.list)
        }
    }

    const getStudentsList = async () => {
        let response = await getAxiosWithToken({
            url: studentsListEndPoint
        })

        if (response != null) {
            let tempList = []
            for (var i of response.data.list) {
                var dictData = i;
                dictData['is_new'] = false
                tempList.push(dictData)
            }
            console.log(tempList)
            setStudentsList(response.data.list)
        }
    }

    const onSubmit = async (data) => {

        if (selectedStudentsList.length > 0) {
            let tempStudentList = []
            for (var i of selectedStudentsList) {
                tempStudentList.push(i.id)
            }
            
            data['students'] = tempStudentList

            if (formMode == "create") {
                let response = await postAxiosWithToken({
                    url: batchesEndPoint,
                    body: data
                })
    
                if (response != null) {
                    toast.success(response.message)
                    navigate(-1)
                }
            } else {
                let tempNewStudentList = []
                for (var i of addedStudents) {
                    tempNewStudentList.push(i.id)
                }

                data['added_students'] = tempNewStudentList
                let response = await putAxiosWithToken({
                    url: batchesEndPoint + id + "/",
                    body: data
                })
    
                if (response != null) {
                    toast.success(response.message,)
                    navigate(-1)
                }
            }
        } else {
            toast.info("Select atleast one student")
        }


    }

    const resetForm = () => {
        reset();
        setSelectedCourse("")
        setSelectedStudentsList([])
    }

    const onStudentSelected = (item) => {
        var isOldValid = true;
        for (var i of selectedStudentsList) {
            if (i.id == item.id) {
                isOldValid = false;
                break;
            }
        }

        var isNewValid = true;
        for (var i of addedStudents) {
            if (i.id == item.id) {
                isNewValid = false;
                break;
            }
        }

        if (isNewValid && isOldValid) {
            if (formMode == "update") {
                setAddedStudents([...addedStudents, item])
            } else {
                setSelectedStudentsList([...selectedStudentsList, item])
            }
        } else {
            if (!isOldValid) {
                toast.info('The student is already added in the old students')
            }
            if (!isNewValid) {
                toast.info('The student is already added in the new students')
            }
        }
    }

    return (
        <div className=" ">
            <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Course{selectedCourse}</p>
                        <select {...register("course_details")} onChange={(e) => {
                            setSelectedCourse(e.target.value)
                        }} value={selectedCourse} name="course_details" className="border rounded p-[7px] w-full text-[14px] bg-white" >
                            <option value={""}></option>
                            {
                                courseList.map((item, index) => (
                                    <option value={item.id}>{item.title}</option>
                                ))
                            }
                        </select>
                        <p className="text-[12px] text-red-600">{errors.course_details?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Start Date</p>
                        <input {...register("start_date")} name="start_date" type="date" placeholder="Enter your start date" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.start_date?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">End Date</p>
                        <input {...register("end_date")} name="end_date" type="date" placeholder="Enter your end date" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.end_date?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Students</p>
                        <div className="relative">
                            <input type="search" onClick={() => {
                                setOpenDropdown(!openDropdown)
                            }} placeholder="search students" className="border rounded p-[7px] w-full text-[14px]" />
                            <div className={`${openDropdown ? 'block' : 'hidden'} absolute  w-full bg-white rounded-lg shadow max-h-[150px] overflow-y-scroll`}>
                                {
                                    studentsList.map((item, index) => (
                                        <div onClick={() => {
                                            onStudentSelected(item)
                                            setOpenDropdown(!openDropdown)
                                        }} className="p-[10px] hover:bg-primary hover:bg-opacity-[0.3]">{item.student_name} ({item.phone_number})</div>
                                        
                                    ))
                                }
                                {/* <ToastContainer /> */}
                            </div>
                        </div>
                        <p className="text-[12px] text-red-600">{errors.students?.message}</p>
                    </div>
                </div>
                
                {
                    formMode == "update" && <SelectedStudentsTable itemsList={addedStudents} setItemsList={setAddedStudents} />
                }
                <SelectedStudentsTable itemsList={selectedStudentsList} setItemsList={setSelectedStudentsList} />

                <div className="flex justify-end items-center gap-3">
                    <button onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }} className="my-[20px] bg-gray-200 border text-secondary text-[14px] w-[150px] rounded p-[5px]">Clear</button>
                    <button type="submit" className="my-[20px] bg-primary text-white text-[14px] w-[150px] rounded p-[5px]">Save</button>
                    {/* <ToastContainer /> */}
           
                </div>
            </form>
        </div>
    );
}