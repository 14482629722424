export default function Attendence() {
    return (
        <div className="w-full bg-white rounded-lg  p-[15px]">
            <div className=" flex justify-between items-center mb-[10px]">
                <p className="text-[12px] sm:text-[16px] sm:tracking-[1px]">Attendence</p>
                <button onClick={(e) => {
                    e.preventDefault();
                }} className="bg-primary text-white text-[12px] sm:text-[16px] w-[75px] sm:w-[100px] h-[30px] sm:h-[40px] rounded font-[500]">Export</button>
            </div>

            <div className=" overflow-scroll ">
                <table className="w-full">
                    <thead className="bg-primary text-white text-[12px] sm:text-[16px] ">
                        <tr>
                            <th className="p-[10px] text-left">S No</th>
                            <th className="p-[10px] text-center">Date</th>
                            <th className="p-[10px] text-center">Python</th>
                            <th className="p-[10px] text-center">Java</th>
                            <th className="p-[10px] text-center">Cloud Computing</th>
                        </tr>
                    </thead>
                    <tbody className="text-[12px] sm:text-[16px] ">
                        <tr>
                            <td className="p-[10px] text-left">1</td>
                            <td className="p-[10px] text-center">21-09-2024</td>
                            <td className="p-[10px] text-center">
                                <span className="i-teenyicons-tick-circle-solid text-green-600 w-[15px] h-[15px]"></span>
                            </td>
                            <td className="p-[10px] text-center">
                                <span className="i-octicon-x-circle-fill-16 text-red-600 w-[15px] h-[15px]"></span>
                            </td>
                            <td className="p-[10px] text-center">
                                <span className="i-teenyicons-tick-circle-solid text-green-600 w-[15px] h-[15px]"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>


            </div>
            {/* <div className="mt-[5px]">
                <Pagination totalPages={totalPages} currentPage={tableFilters.page} setCurrentPage={pageHandler} entries={tableFilters.itemsperpage} entriesHandler={entriesHandler} />
            </div> */}
        </div>
    );
}