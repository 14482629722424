import { useEffect, useState, } from "react";
import { useNavigate } from "react-router-dom";
import { trainerScheduleListEndPoint, trainertaskEndPoint } from "../../../services/api_endpoints";
import { postAxiosWithToken } from "../../../services/axios_service";
import PieCharts from "../../charts/charts";
import AreaBaseline from "../../charts/linecharts";

export default function DashboardTrainer() {

    const navigate = useNavigate();

    const [tasktableFilters, setTaskTableFilters] = useState({
        student: null,
        session: null,
        batch: null,
        trainer: null,
        course: null,
        task_status: null,
        page: 1,
        items_per_page: 10
    });

    const [tableFilters, settrainerTableFilters] = useState({
        search: "",
        page: 1,
        itemsperpage: 1000,
    });
    const [sessionsList, setSessionsList] = useState();
    const [taskList, settasktList] = useState();
    const [liveList, setLiveList] = useState();
    const [recordList, setrecordtList] = useState();

    useEffect(() => {
        getTaskList()
        getSessionList()
    }, [tableFilters, tasktableFilters])

    const getSessionList = async () => {
        let response = await postAxiosWithToken({
            url: trainerScheduleListEndPoint,
            body: tableFilters
        });

        if (response != null) {
            setSessionsList(response.data.list.length);
            const liveSessions = response.data.list.filter(session => session.session_type === 'live');
            const recordedSessions = response.data.list.filter(session => session.session_type === 'recording');
            setLiveList(liveSessions.length)
            setrecordtList(recordedSessions.length)

        }
    }

    const getTaskList = async () => {
        let response = await postAxiosWithToken({
            url: trainertaskEndPoint,
            body: tasktableFilters
        });
        if (response != null) {
            settasktList(response.data.total_items)
        }
    };

    return (
        <div className="w-full flex flex-wrap items-center gap-[25px] overflow-x-hidden">
            <div className="bg-white rounded-lg p-[15px] w-[163px] md:w-[200px] cursor-pointer" onClick={(e) => {
                e.preventDefault();
                navigate('/schedule');
            }}>
                <div className="pb-[10px] w-full flex justify-between items-center" >
                    <p>Total Sessions</p>
                    <span className="i-mingcute-arrow-right-line w-[20px] h-[20px]"></span>
                </div>
                {/* <p>{sessionsList}</p> */}
                <PieCharts value={sessionsList || 0} colors={'#00ecff'} />
            </div>
            <div className="bg-white rounded-lg p-[15px] w-[163px] md:w-[200px] cursor-pointer" onClick={(e) => {
                e.preventDefault();
                navigate('/schedule');
            }}>
                <div className="pb-[10px] w-full flex justify-between items-center" >
                    <p>Live Sessions</p>
                    <span className="i-mingcute-arrow-right-line w-[20px] h-[20px]"></span>
                </div>
                <PieCharts value={liveList || 0} colors={'#1890ff'} />

            </div>

            <div className="bg-white rounded-lg p-[15px] w-[163px] md:w-[200px] cursor-pointer" onClick={(e) => {
                e.preventDefault();
                navigate('/schedule');
            }}>
                <div className="pb-[10px] w-full flex justify-between items-center" >
                    <p>Recorded Session</p>
                    <span className="i-mingcute-arrow-right-line w-[20px] h-[20px]"></span>
                </div>
                <PieCharts value={recordList || 0} colors={'#52c41a'} />

            </div>

            <div className="bg-white rounded-lg p-[15px] w-[163px] md:w-[200px] cursor-pointer" onClick={(e) => {
                e.preventDefault();
                navigate('/task');
            }}>
                <div className="pb-[10px] w-full flex justify-between items-center" >
                    <p>Total Task</p>
                    <span className="i-mingcute-arrow-right-line w-[20px] h-[20px]"></span>
                </div>
                {/* <p>{taskList}</p> */}
                <PieCharts value={taskList || 0} colors={'#faad14'} />
            </div>
            <div className="bg-white rounded-lg p-[15px] cursor-pointer overflow-x-auto" >
                   <AreaBaseline/>
            </div>
        </div>  
    );
}
