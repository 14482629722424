import { useEffect, useState } from "react";
import { siteCourseListEndPoint } from "../../../services/api_endpoints";
import { getAxiosWithToken } from "../../../services/axios_service";

export default function ExploreCourses() {

    const [courseList, setCourseList] = useState([]);

    useEffect(() => {
        getCourseList()
    }, [])

    const getCourseList = async () => {
        let response = await getAxiosWithToken({
            url: siteCourseListEndPoint
        });

        if (response != null) {
            setCourseList(response.data.list)
        }
    }

    return (
        <div className="w-full bg-white rounded-lg  p-[15px]">
            <div className=" flex justify-between items-center mb-[10px]">
                <p className="text-[12px] sm:text-[16px] sm:tracking-[1px]">Explore Courses</p>
                <button onClick={(e) => {
                    e.preventDefault();
                }} className="bg-primary text-white text-[12px] sm:text-[16px] w-[75px] sm:w-[100px] h-[30px] sm:h-[40px] rounded font-[500]">Contact</button>
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-[25px]">
                {
                    courseList.map((item) => (
                        <div className="border rounded-lg bg-white shadow-xl p-[25px] w-fit">
                            <div className="w-full h-[150px] flex items-center overflow-hidden">
                                <img src={item.thumbnail} />
                            </div>
                            <div className="flex justify-between items-center my-[10px]">
                                <p className="capitalize text-[14px] bg-gray-600 text-white px-[10px] rounded-full py-1">{item.category_name}</p>
                                <p className="capitalize text-[14px] px-[10px]">{item.review} Reviews</p>
                            </div>
                            <p>{item.title}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}