import { useNavigate } from "react-router-dom";
import { logoutEndPoint } from "../../services/api_endpoints";
import { getAxiosWithToken } from "../../services/axios_service";

export default function Header({
    setMenuOption,
    menuOption,
    userName
}) {

    const navigate = useNavigate();

    const handleLogout = async () => {
        let response = await getAxiosWithToken({
            url: logoutEndPoint
        });

        if (response != null) {
            localStorage.removeItem("login_details");
            localStorage.removeItem("token");
            localStorage.setItem("login", JSON.stringify(false));
            navigate('/')
            window.location.reload()
        } else {
            navigate('/') 
        }

    }

    return (
        <div className="bg-white drop-shadow-lg h-[65px] w-full flex items-center justify-between px-[24px]">
            <div className="flex items-center gap-[20px]">
                <button onClick={(e) => {
                    e.preventDefault();
                    setMenuOption(!menuOption);
                }} className="md:hidden">
                    <span className="i-ion-menu-outline w-[30px] h-[30px]"></span>
                </button>
                <p className="capitalize">Welcome, {userName}</p>
            </div>

            <div className="flex items-center gap-[15px]">
                {/* <span className="i-clarity-notification-line w-[25px] h-[25px] text-gray-500"></span>
                <div className="w-[50px] h-[50px] rounded-full bg-gray-300">

                </div> */}
                <button onClick={(e) => {e.preventDefault(); handleLogout()}} className="">
                    <span className="i-ant-design-logout-outlined w-[25px] h-[25px] text-gray-500"></span>
                </button>

            </div>
        </div>
    );
}