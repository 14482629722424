import { useEffect, useState } from "react";
import { admintaskEndPoint, sessionListEndPoint, courseListEndPoint, batchesListEndPoint, trainersListEndPoint, s3ImageUploadEndPoint, studentsListEndPoint, batchesEndPoint } from "../../../services/api_endpoints";
import { getAxiosWithToken, postAxiosWithToken } from "../../../services/axios_service";
import Pagination from "../../../components/Pagination";
import { getDateInText } from "../../../utilities/datetime_utils";
import TaskUpdateModal from "./view_task";
import TaskAddModal from "./add_task";

export default function Task() {
    const [trainersList, setTrainersList] = useState([]);
    const [currentItem, setCurrentItem] = useState({});
    const [totalPages, setTotalPages] = useState(1);
    const [courseList, setCourseList] = useState([]);
    const [studentsList, setStudentsList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [TrainerList, setTrainersLists] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isaddModalOpen, setIsaddModalOpen] = useState(false);
    const [selectedItemId, setValue] = useState(0);

    const [tableFilters, setTableFilters] = useState({
        student: null,
        session: null,
        batch: null,
        trainer: null,
        course: null,
        task_status: null,
        page: 1,
        items_per_page: 10
    });

    useEffect(() => {
        getTrainersList();
        getCourseList();
        getStudentsList();
        getBatchList();
        getSessionList();
        getTrainerList();
    }, [tableFilters]);

    const getCourseList = async () => {
        let response = await getAxiosWithToken({
            url: courseListEndPoint
        })
        if (response != null) {
            setCourseList(response.data.list);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        const parsedValue = ["student", "session", "batch", "trainer", "course"].includes(name) && value !== ""
            ? parseInt(value, 10)
            : value;

        setTableFilters((prevState) => ({
            ...prevState,
            [name]: parsedValue,
            page: 1
        }));
    };

    const getStudentsList = async () => {
        let response = await getAxiosWithToken({
            url: studentsListEndPoint
        })
        if (response != null) {
            let tempList = [];
            for (var i of response.data.list) {
                var dictData = i;
                dictData['is_new'] = false;
                tempList.push(dictData);
            }
            setStudentsList(response.data.list);
        }
    };

    const getBatchList = async () => {
        let response = await getAxiosWithToken({
            url: batchesListEndPoint
        });
        if (response != null) {
            setBatchList(response.data.list);
        }
    };

    const getSessionList = async () => {
        let response = await getAxiosWithToken({
            url: sessionListEndPoint
        });
        if (response != null) {
            setSessions(response.data.list);
        }
    };

    const getTrainerList = async () => {
        let response = await getAxiosWithToken({
            url: trainersListEndPoint
        });
        if (response != null) {
            setTrainersLists(response.data.list);
        }
    };

    const getTrainersList = async () => {
        let response = await postAxiosWithToken({
            url: admintaskEndPoint,
            body: tableFilters
        });
        if (response != null) {
            setTrainersList(response.data.list);
            setTotalPages(response.data.total_pages);
        }
    };



    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);

    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        getTrainersList();
    };

    const handleCloseaddModal = () => {
        setIsaddModalOpen(false);
    };

    return (
        <div className="w-full bg-white rounded-lg p-[15px]">
            <div className="flex justify-between items-center mb-[10px]">
                <p className="text-[12px] sm:text-[16px] sm:tracking-[1px]">Manage Task</p>
                <button onClick={(e) => {
                    e.preventDefault();
                    setTableFilters({
                        student: null,
                        session: null,
                        batch: null,
                        trainer: null,
                        course: null,
                        task_status: null,
                        page: 1,
                        items_per_page: 10
                    });
                }} className="bg-primary text-white text-[12px] sm:text-[16px] w-[75px] sm:w-[100px] h-[30px] sm:h-[40px] rounded font-[500]">Clear</button>
            </div>
            <div className="mb-[5px] overflow-x-auto" >
                <div className="flex gap-2 mt-5 mb-5">
                    <select name="student" onChange={handleFilterChange} value={tableFilters.student || ''} className="p-2 border rounded">
                        <option value={null}>Select Student</option>
                        {studentsList.map((student) => (
                            <option key={student.id} value={student.id}>{student.student_name}</option>
                        ))}
                    </select>
                    <select name="session" onChange={handleFilterChange} value={tableFilters.session || ''} className="p-2 border rounded">
                        <option value={null}>Select Session</option>
                        {sessions.map((session) => (
                            <option key={session.session_no} value={session.session_no}>{session.session_no}</option>
                        ))}
                    </select>
                    <select name="batch" onChange={handleFilterChange} value={tableFilters.batch || ''} className="p-2 border rounded">
                        <option value={null}>Select Batch</option>
                        {batchList.map((batch) => (
                            <option key={batch.batch_no} value={batch.batch_no}>{batch.batch_no}</option>
                        ))}
                    </select>
                    {/* <select name="course" onChange={handleFilterChange} value={tableFilters.course || ''} className="p-2 border rounded">
                        <option value={null}>Select Course</option>
                        {courseList.map((course) => (
                            <option key={course.id} value={course.id}>{course.category_name}</option>
                        ))}
                    </select> */}
                    <select name="trainer" onChange={handleFilterChange} value={tableFilters.trainer || ''} className="p-2 border rounded">
                        <option value={null}>Select Trainer</option>
                        {TrainerList.map((trainer) => (
                            <option key={trainer.id} value={trainer.id}>{trainer.trainer_name}</option>
                        ))}
                    </select>
                    <select name="task_status" onChange={handleFilterChange} value={tableFilters.task_status || ''} className="p-2 border rounded">
                        <option value={null}>Select Task Status</option>
                        <option value='Validated'>Validated</option>
                        <option value='Not Validated'>Not Validated</option>
                    </select>
                </div>
            </div>
            <div className="overflow-scroll">


                <table className="w-full">
                    <thead className="bg-primary text-white text-[12px] sm:text-[16px]">
                        <tr>
                            <th className="p-[10px] text-left">S No</th>
                            <th className="p-[10px] text-left">Trainer Name</th>
                            <th className="p-[10px] text-left">Student Name</th>
                            <th className="p-[10px] text-left">Session No</th>
                            <th className="p-[10px] text-left">Task Status</th>
                            <th className="p-[10px] text-left">Validated Date</th>
                            <th className="p-[10px] text-left">Action</th>
                        </tr>
                    </thead>
                    <tbody className="text-[12px] sm:text-[16px]">
                        {trainersList.map((item, index) => (
                            <tr className={`${index % 2 === 0 ? "bg-gray-100" : ""}`} key={index}>
                                <td className="p-[10px]">{(tableFilters.page - 1) * tableFilters.items_per_page + index + 1}</td>
                                <td className="p-[10px]">{item.trainer_name}</td>
                                <td className="p-[10px]">{item.student_name}</td>
                                <td className="p-[10px]">{item.session_no}</td>
                                <td className="p-[10px]">{item.task_status}</td>
                                <td className="p-[10px]">{item.validated_date ? getDateInText(item.validated_date) : ''}</td>
                                <td className="p-[10px]">
                                    <div className="flex gap-3">
                                        <button onClick={() => {
                                            setValue(item.id);
                                            handleOpenModal();
                                            setCurrentItem(item);
                                        }} className=""><span className="i-mingcute-edit-4-line w-[25px] h-[25px]"></span></button>
                                        {/* <button onClick={() => {
                                            setValue(item.id);
                                            handleOpenModal();
                                        setCurrentItem(item);
                                        setFormMode("view");
                                    }} className=""><span className="i-mdi-eye w-[25px] h-[25px]"></span></button>  */}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>


            </div>
            <div className="mt-[5px]">
                <Pagination totalPages={totalPages} currentPage={tableFilters.page} setCurrentPage={pageHandler} entries={tableFilters.itemsperpage} entriesHandler={entriesHandler} />
            </div>
            {isModalOpen && (
                <TaskUpdateModal
                    onClose={handleCloseModal}
                    id={selectedItemId}
                />

            )}
            {isaddModalOpen && (
                <TaskAddModal
                    onClose={handleCloseaddModal}
                    id={selectedItemId}
                />

            )}
        </div>
    );
}
