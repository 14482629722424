import { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination";
import { sessionListEndPoint, trainerScheduleListEndPoint } from "../../../services/api_endpoints";
import { postAxiosWithToken } from "../../../services/axios_service";
import { getDateAndTimeText, getDateInText } from "../../../utilities/datetime_utils";
import { useNavigate } from "react-router-dom";
import SessionStatus from './session_status';
import TaskAddModal from "./add_task";

export default function Schedule() {
    const navigate = useNavigate();
    const [isaddModalOpen, setIsaddModalOpen] = useState(false);

    const [sessionsList, setSessionsList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedItemId, setValue] = useState(0);

    const [tableFilters, setTableFilters] = useState({
        search: "",
        page: 1,
        itemsperpage: 10
    })
    const [showModal, setShowModal] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);

    useEffect(() => {
        getSessionList()
    }, [tableFilters])

    const getSessionList = async () => {
        let response = await postAxiosWithToken({
            url: trainerScheduleListEndPoint,
            body: tableFilters
        });

        if (response != null) {
            setSessionsList(response.data.list)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            itemsperpage: value,
            page: 1,
        }));
    };

    const showmodel = (session) => {
        setSelectedSession(session);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedSession(null);
        getSessionList();
    };

    const handleSave = () => {
        console.log("Saved data for session:", selectedSession);
        handleCloseModal();
    };


    const handleOpenaddModal = () => {
        setIsaddModalOpen(true); // Open modal when called
    };

    const handleCloseaddModal = () => {
        setIsaddModalOpen(false); // Close modal when called
    };

    return (
        <div className="w-full bg-white rounded-lg  p-[15px]">
            <div className=" flex justify-between items-center mb-[10px]">
                <p className="text-[12px] sm:text-[16px] sm:tracking-[1px]">Schedule</p>
            </div>

            {/* <div className="mb-[5px]">
                <SessionTableFilter filters={tableFilters} setFilters={setTableFilters} />
            </div> */}
            <div className=" overflow-scroll ">
                <table className="w-full">
                    <thead className="bg-primary text-white text-[12px] sm:text-[16px] ">
                        <tr>
                            <th className="p-[10px] text-left min-w-[75px]">S No</th>
                            <th className="p-[10px] text-left min-w-[150px]">Session No</th>
                            <th className="p-[10px] text-left min-w-[150px]">Batch No</th>
                            <th className="p-[10px] text-left min-w-[150px]">Course</th>
                            <th className="p-[10px] text-left min-w-[150px]">Date</th>
                            <th className="p-[10px] text-left min-w-[150px]">Start Time</th>
                            <th className="p-[10px] text-left min-w-[150px]">End Time</th>
                            <th className="p-[10px] text-left min-w-[150px]">Action</th>
                        </tr>
                    </thead>
                    <tbody className="text-[12px] sm:text-[16px] ">
                        {
                            sessionsList.map((item, index) => (
                                <tr className={`${index % 2 == 0 ? "" : "bg-gray-100"} max-h-[20px] `} key={"trainer" + index}>
                                    <td className="px-[10px] py-[5px] text-left">{(tableFilters.page - 1) * tableFilters.itemsperpage + index + 1}</td>
                                    <td className="px-[10px] py-[5px] text-left">{item.session_no}</td>
                                    <td className="px-[10px] py-[5px] text-left">{item.batch_number}</td>
                                    <td className="px-[10px] py-[5px] text-left">{item.course_title}</td>
                                    <td className="px-[10px] py-[5px] text-left">{getDateInText(item.session_date)}</td>
                                    <td className="px-[10px] py-[5px] text-left">{getDateAndTimeText(item.start_time)}</td>
                                    <td className="px-[10px] py-[5px] text-left">{getDateAndTimeText(item.end_time)}</td>
                                    <td className="px-[10px] py-[5px] text-left">
                                        <div className="flex">
                                            {/* <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    console.log(item.session_no);
                                                    navigate("/schedule-details/" + item.session_no)
                                                }}
                                                className="rounded w-[35px] h-[35px] text-black flex items-center justify-center"><span className="i-streamline-visible w-[25px] h-[25px]"></span></button> */}
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    showmodel(item);
                                                    console.log(item);

                                                }}
                                                className="rounded w-[35px] h-[35px] text-black flex items-center justify-center"><span className="i-mingcute-edit-4-line w-[25px] h-[25px]"></span></button>
                                            {/* <button onClick={() => {
                                                setValue(item.session_no);
                                                handleOpenaddModal();

                                            }} className=""><span className="i-mdi-eye w-[25px] h-[25px]"></span></button> */}

                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>


            </div>
            <div className="mt-[5px]">
                <Pagination totalPages={totalPages} currentPage={tableFilters.page} setCurrentPage={pageHandler} entries={tableFilters.itemsperpage} entriesHandler={entriesHandler} />
            </div>

            <SessionStatus
                show={showModal}
                onClose={handleCloseModal}
                onSave={handleSave}
                title="Session Status"
                session={selectedSession}
            />
            {isaddModalOpen && (
                <TaskAddModal
                    onClose={handleCloseaddModal}
                    id={selectedItemId}
                />
            )}
        </div>
    );
}