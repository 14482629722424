import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

const today = new Date();
const time = [
  new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()),
  new Date(today.getFullYear(), today.getMonth() - 5, today.getDate()),
  new Date(today.getFullYear(), today.getMonth() - 4, today.getDate()),
  new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()),
  new Date(today.getFullYear(), today.getMonth() - 2, today.getDate()),
  new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
  today,
];

const a = [40, 30, 20, 20, 40, 30, 110];

export default function AreaBaselines() {
  return (
    <LineChart
      width={600}
      height={300}
      grid={{ vertical: false, horizontal: true }}
      series={[
        {
          data: a,
          type: 'line',
          label: 'Percentage',
          area: true,
          stack: 'total',
          showMark: false,
        },
       
      ]}
      xAxis={[
        {
          scaleType: 'time',
          data: time,
          min: time[0].getTime(),
          max: time[time.length - 1].getTime(),
        },
      ]}
    />
  );
}
