import { courseListEndPoint, s3ImageUploadEndPoint, studentsListEndPoint, batchesEndPoint, batchesListEndPoint, trainersListEndPoint, sessionEndPoint } from "../../../services/api_endpoints";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { ToastContainer, toast } from "react-toastify";
import { getAxiosWithToken, postAxiosWithToken, putAxiosWithToken } from "../../../services/axios_service";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTimeText } from "../../../utilities/datetime_utils";

export default function SessionForm() {

    const navigate = useNavigate();

    const { id } = useParams();

    const [formMode, setFormMode] = useState("create");
    const [currentItem, setCurrentItem] = useState({});
    const [batchList, setBatchList] = useState([]);
    const [trainersList, setTrainersList] = useState([]);

    const [selectedBatch, setSelectedBatch] = useState("");
    const [selectedTrainer, setSelectedTrainer] = useState("");
    const [selectedSessionType, setSelectedSessionType] = useState("");
    const [syllabusList, setSyllabusList] = useState([]);

    const [currentSyllabus, setCurrentSyllabus] = useState("");
    const [currentSyllabusIndex, setCurrentSyllabusIndex] = useState(null);
    const [currentSyllabusMode, setCurrentSyllabusMode] = useState("add");

    const schema = yup
        .object({
            session_title: yup.string().required(),
            session_description: yup.string().required(),
            session_type: yup.string().required(),
            batch_details: yup.string().required(),
            trainer_details: yup.string().required(),
            syllabus: yup.array().test(
                'syllabus',
                'At least one syllabus topic must be added', // Custom error message
                (value) => syllabusList.length > 0 // Custom validation logic
            ),
            session_date: yup.string().required(),
            start_time: yup.string().required(),
            end_time: yup.string().required(),
            tast_link: yup.string().required(),

            meet_link: selectedSessionType == 'live' ? yup.string().required() : yup.string(),
            recording_link: selectedSessionType == 'recording' ? yup.string().required() : yup.string(),
        })
        .required()

    const {
        setValue,
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
    })

    useEffect(() => {

        if (id) {
            setFormMode("update")
            getSessionDetails()
        }

        getBatchList();
        getTrainersList();
    }, [])


    const getTimeTextdef = (datetime) => {
        const dateObj = new Date(datetime);
        const hours = dateObj.getHours().toString().padStart(2, "0");
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    };

    const getSessionDetails = async () => {
        let response = await getAxiosWithToken({
            url: sessionEndPoint + id + "/"
        })

        if (response != null) {
            const startTime = getTimeTextdef(response.data.start_time); 
            const endTime = getTimeTextdef(response.data.end_time);
    
            //  console.log(endTime);
            setValue('session_title', response.data.session_title)
            setValue('session_description', response.data.session_description)
            setValue('batch_details', response.data.batch_details)
            setValue('session_type', response.data.session_type)
            setValue('trainer_details', response.data.trainer_details)
            setValue("start_time", startTime); 
            setValue("end_time", endTime); 
            setValue('session_date', response.data.session_date)
            setValue('recording_link', response.data.recording_link)
            setValue('meet_link', response.data.meet_link)
            setValue('tast_link', response.data.tast_link)

            setSelectedBatch(response.data.batch_details)
            setSelectedSessionType(response.data.session_type)
            setSelectedTrainer(response.data.trainer_details)

            setSyllabusList(response.data.syllabus)
        }
    }

    const getBatchList = async () => {
        let response = await getAxiosWithToken({
            url: batchesListEndPoint
        })

        if (response != null) {
            setBatchList(response.data.list)
        }
    }

    const getTrainersList = async () => {
        let response = await getAxiosWithToken({
            url: trainersListEndPoint
        })

        if (response != null) {
            setTrainersList(response.data.list)
        }
    }
    
    const onSubmit = async (data) => {
        

        data['start_time'] = data['session_date'] + " " + data['start_time']
        data['end_time'] = data['session_date'] + " " + data['end_time']

        data['syllabus'] = syllabusList

        if (formMode == "create") {
            let response = await postAxiosWithToken({
                url: sessionEndPoint,
                body: data
            })

            if (response != null) {
                toast.success(response.message)
                navigate(-1)
                setFormMode("create")
            }
        } else {
            let response = await putAxiosWithToken({
                url: sessionEndPoint + id + "/",
                body: data
            })

            if (response != null) {
                navigate(-1)
                toast.success(response.message)
                setFormMode("create")
            }
        }

    }

    const resetForm = () => {
        reset();
        setSelectedBatch("")
        setSelectedSessionType("")
        setSelectedTrainer("")
        setSyllabusList([])
    }

    return (
        <div className=" ">
            <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                <div className="sm:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Session Title</p>
                        <input {...register("session_title")} name="session_title" type="text" placeholder="Enter your session title" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.session_title?.message}</p>
                    </div>
                    <div className="col-span-4">
                        <p className="text-[14px] mb-[5px] text-primary">Session Description</p>
                        <textarea {...register("session_description")} name="session_description" type="text" placeholder="Enter your session description" className="border rounded p-[7px] w-full text-[14px]"></textarea>
                        <p className="text-[12px] text-red-600">{errors.session_description?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Batch</p>
                        <select {...register("batch_details")} onChange={(e) => {
                            setSelectedBatch(e.target.value)
                        }} value={selectedBatch} name="batch_details" className="border rounded p-[7px] w-full text-[14px] bg-white" >
                            <option value={""}></option>
                            {
                                batchList.map((item, index) => (
                                    <option value={item.id}>{item.batch_no}</option>
                                ))
                            }
                        </select>
                        <p className="text-[12px] text-red-600">{errors.batch_details?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Trainer</p>
                        <select {...register("trainer_details")} onChange={(e) => {
                            setSelectedTrainer(e.target.value)
                        }} value={selectedTrainer} name="trainer_details" className="border rounded p-[7px] w-full text-[14px] bg-white" >
                            <option value={""}></option>
                            {
                                trainersList.map((item, index) => (
                                    <option value={item.id}>{item.trainer_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-[12px] text-red-600">{errors.trainer_details?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Session Type</p>
                        <select {...register("session_type")} onChange={(e) => {
                            setSelectedSessionType(e.target.value)
                        }} value={selectedSessionType} name="session_type" className="border rounded p-[7px] w-full text-[14px] bg-white" >
                            <option value={""}></option>
                            <option value={"live"}>Live</option>
                            <option value={"recording"}>Recording</option>
                            <option value={"upcoming"}>Upcoming</option>
                            <option value={"completed"}>Completed</option>
                        </select>
                        <p className="text-[12px] text-red-600">{errors.session_type?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Date</p>
                        <input {...register("session_date")} name="session_date" type="date" placeholder="Enter your date" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.session_date?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Start Time</p>
                        <input {...register("start_time")} name="start_time" type="time" placeholder="Enter your start time" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.start_time?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">End Time</p>
                        <input {...register("end_time")} name="end_time" type="time" placeholder="Enter your end time" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.end_time?.message}</p>
                    </div>
                    {selectedSessionType == "live" ? <div>
                        <p className="text-[14px] mb-[5px] text-primary">Live Link</p>
                        <input {...register("meet_link")} name="meet_link" type="text" placeholder="Enter your live link" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.meet_link?.message}</p>
                    </div> : selectedSessionType == "recording" ?
                        <div>
                            <p className="text-[14px] mb-[5px] text-primary">Recording Link</p>
                            <input {...register("recording_link")} name="recording_link" type="text" placeholder="Enter your recording link" className="border rounded p-[7px] w-full text-[14px]" />
                            <p className="text-[12px] text-red-600">{errors.recording_link?.message}</p>
                        </div> : null}
                        <div>
                    <p className="text-[14px] mb-[5px] text-primary">Task Link</p>
                        <input {...register("tast_link")} name="tast_link" type="text" placeholder="Enter your Task link" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.tast_link?.message}</p>
                    </div>  
                    <div className="sm:col-span-2 lg:col-span-4">
                        <p className="text-[14px] mb-[5px] text-primary">Syllabus</p>
                        <div className="flex gap-3 ">
                            <input onChange={(e) => { setCurrentSyllabus(e.target.value) }} value={currentSyllabus} name="syllabus" type="text" placeholder="Add here topic name" className="border rounded p-[7px] w-full text-[14px]" />
                            <button onClick={(e) => {
                                e.preventDefault();
                                if (currentSyllabus.length > 0) {
                                    if (currentSyllabusMode == "add") {
                                        setSyllabusList([...syllabusList, currentSyllabus])
                                    } else {
                                        let data = [...syllabusList];
                                        data[currentSyllabusIndex] = currentSyllabus
                                        setSyllabusList(data)
                                    }
                                }
                                setCurrentSyllabus("")
                                setCurrentSyllabusMode('add')
                                setCurrentSyllabusIndex(null)
                            }} className="bg-primary text-white text-[14px] w-[150px] rounded p-[5px] capitalize">{currentSyllabusMode}</button>
                        </div>
                        <p className="text-[12px] text-red-600">{errors.syllabus?.message}</p>
                    </div>
                </div>

                <div className=" w-full mt-[10px] ">
                    {
                        syllabusList.map((item, index) => (
                            <div className="flex gap-3 items-end my-[5px]">
                                <p className="w-full bg-white border p-2">{item}</p>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentSyllabus(item)
                                    setCurrentSyllabusMode("update")
                                    setCurrentSyllabusIndex(index)
                                }} className="bg-gray-200 h-[30px] border text-primary text-[14px] w-[150px] rounded p-[5px]">Update</button>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    let data = [...syllabusList];
                                    data.splice(index, 1)
                                    setSyllabusList(data)
                                }} className="bg-gray-200 h-[30px] border text-primary text-[14px] w-[150px] rounded p-[5px]">Remove</button>
                            </div>
                        ))
                    }
                </div>

                <div className="flex justify-end items-center gap-3 ">
                    <button onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }} className="my-[20px] bg-gray-200 border text-primary text-[14px] w-[150px] rounded p-[5px]">Clear</button>
                    <button type="submit" className="my-[20px] bg-primary text-white text-[14px] w-[150px] rounded p-[5px]">Save</button>
                    {/* <ToastContainer /> */}
                </div>
            </form>
        </div>
    );
}