import { useState } from "react";
import { removeStudentFromBatchEndPoint } from "../../../services/api_endpoints";
import { deleteAxiosWithToken } from "../../../services/axios_service";

export default function SelectedStudentsTable({
    itemsList,
    setItemsList
}) {

    const [isDeleteLoading, setIsDeleteLoading] = useState(null)

    const removeStudent = async (item, index) => {
        if (item.is_db) {

            let response = await deleteAxiosWithToken({
                url: removeStudentFromBatchEndPoint + item.batch_student_id + '/'
            })

            if (response != null) {
                let data = [...itemsList];
                data.splice(index, 1)
                setItemsList(data)
            }
            
        } else {
            let data = [...itemsList];
            data.splice(index, 1)
            setItemsList(data)
        }
    }
    return (
        <div className="w-full my-[10px]">
            <table className="w-full">
                
                    
            {itemsList.length > 0 ? (
                <thead  className="bg-primary text-white text-[12px] sm:text-[16px] ">
                    <tr>
                        <th className="p-[10px] text-left">S.No</th>
                        <th className="p-[10px] text-left">Name</th>
                        <th className="p-[10px] text-left">Email</th>
                        <th className="p-[10px] text-left">Mobile No</th>
                        <th className="p-[10px] text-left">Action</th>
                    </tr>
                </thead>
            ): null}
                <tbody>
                    {
                        itemsList.map((item, index) => (
                            <tr>
                                <td className="px-[10px] py-[5px] text-left">{index + 1}</td>
                                <td className="px-[10px] py-[5px] text-left">
                                    <div className="flex gap-3 items-center">
                                        <p>{item.student_name}</p>
                                        { item.is_new &&
                                            <p className="bg-primery bg-opacity-[0.3] text-secondary">New</p>
                                        }
                                    </div>
                                </td>
                                <td className="px-[10px] py-[5px] text-left">{item.email}</td>
                                <td className="px-[10px] py-[5px] text-left">{item.phone_number}</td>
                                <td className="px-[10px] py-[5px] text-left">
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        removeStudent(item, index)
                                    }} className="rounded w-[35px] h-[35px] text-black flex items-center justify-center"><span className="i-mingcute-delete-2-line w-[25px] h-[25px]"></span></button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}