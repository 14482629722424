import { useEffect, useState } from "react";
import { taskResponseEndPoint, taskvalidationEndPoint } from "../../../services/api_endpoints";
import { getAxiosWithToken, postAxiosWithToken } from "../../../services/axios_service";
import { ToastContainer, toast } from "react-toastify";

const TaskUpdateModal = ({ onClose, id }) => {
  const [formData, setFormData] = useState({
    link_comment: "",
    is_valid: null,
  });
  const [taskList, setTaskList] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) {
      handleTaskView(id);
    }
  }, [id]);

  const handleTaskView = async (id) => {
    try {
      const response = await getAxiosWithToken({
        url: taskResponseEndPoint + id + "/",
      });
      if (response && response.status) {
        setTaskList(response.data);
        setFormData({ link_comment: response.data.validated_comments,  is_valid: response.data.is_valid, });

      } else {
        setErrors({ api: "Failed to load the task. Please try again." });
      }
    } catch (error) {
      console.error("Error loading the task:", error);
    }
  };

  const handleSubmitValidation = async (e) => {
    e.preventDefault();
    if(formData.is_valid != null) {
      // console.log(formData.is_valid);
      try {
      const data = {
        id: id,
        is_valid: formData.is_valid,
        validated_comments: formData.link_comment,
      };
      const response = await postAxiosWithToken({
        url: taskvalidationEndPoint,
        body: data,
      });
      onClose();
      toast.success(response.message);

    } catch (error) {
      console.error("Error submitting validation:", error);
    }
    } else {
      toast.warning('Please Check the valid or invalid');
    }
    
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  // const handleCommentChange = (value) => {
  //   setFormData({ link1_comment: value });
  // };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-[2]">
      <div className="bg-white p-6 rounded-md w-[90%] max-w-md max-h-[90vh] overflow-y-auto">
        <h2 className="text-[20px] font-bold mb-4">Task Details</h2>
        <form onSubmit={handleSubmitValidation}>
          {["link_1", "link_2", "link_3", "link_4"].map(
            (link, index) =>
              taskList[link] && (
                <div className="mb-4" key={link}>
                  <p className="flex items-center mb-2">
                    <label className="block text-gray-700 mr-2">Link {index + 1}:</label>
                    <a
                      className="text-blue-500 underline"
                      href={taskList[link]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {taskList[link]}
                    </a>
                  </p>
                 
                </div>
              )
          )}
           <div className="mb-4">
            {/* <p className="text-gray-700">Is Valid:</p> */}
            <label className="mr-4">
              <input
                type="radio"
                name="is_valid"
                value={true}
                checked={formData.is_valid === true}
                onChange={() => handleInputChange("is_valid", true)}
              />
              Valid
            </label>
            <label>
              <input
                type="radio"
                name="is_valid"
                value={false}
                checked={formData.is_valid === false}
                onChange={() => handleInputChange("is_valid", false)}
              />
              Invalid
            </label>
          </div>
         
          <div className="mb-4">
            <textarea
              className="w-full p-2 border rounded"
              placeholder="Add a comment"
              value={formData.link_comment}
              onChange={(e) => handleInputChange("link_comment", e.target.value)}
            />
          </div>
          {/* <textrea
            type="text"
            className="w-full p-2 border rounded"
            placeholder="Add a comment"
            value={formData.link_comment}
            onChange={(e) => handleCommentChange(e.target.value)}
          /> */}
          <div className="flex justify-end gap-4 mt-4">
           <button
              type="button"
              onClick={onClose}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
            {!taskList.validated_date && (
              <button
                type="submit"

                className="bg-primary text-white text-[14px] rounded px-4 py-[5px]"
              >
                Submit
              </button>
            )}
          </div>
          {/* <ToastContainer /> */}
        </form>
      </div>
    </div>
  );
};

export default TaskUpdateModal;
