import { s3ImageUploadEndPoint, studentsEndPoint } from "../../../services/api_endpoints";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios";
import {ToastContainer, toast } from "react-toastify";
import { postAxiosFormWithToken, postAxiosWithToken, putAxiosFormWithToken, putAxiosWithToken } from "../../../services/axios_service";
import { useEffect, useState } from "react";

export default function StudentForm({
    setFormMode,
    formMode,
    fetchData,
    setPopUpOpen,
    currentItem
}) {
    const [imageUrl, setImageUrl] = useState("");

    const schema = yup
        .object({
            student_name: yup.string().required(),
            email: formMode == "update" ? yup.string() : yup.string().email().required(),
            password: formMode == "update" ? yup.string() : yup.string().required(),
            designation: yup.string(),
            qualification: yup.string().required(),
            phone_number: yup.string().required(),
            address: yup.string().required(),
            profile_image: yup.string(),
            passed_out_year: yup.string().required(),
            work_expirience: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: formMode == "update" ? currentItem : {},
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        if(formMode == "update") {
            setImageUrl(currentItem.profile_image)

        }
    }, [currentItem])

    const onSubmit = async (data) => {

        data['profile_image'] = imageUrl

        if (formMode == "create") {
            let response = await postAxiosWithToken({
                url: studentsEndPoint,
                body: data
            })

            if (response != null) {
                toast.success(response.message)
                fetchData()
                setFormMode("create")
            }
        } else {
            let response = await putAxiosWithToken({
                url: studentsEndPoint + currentItem.id + "/",
                body: data
            })

            if (response != null) {
                fetchData()
                toast.success(response.message)
                setFormMode("create")
            }
        }
        setPopUpOpen(false);

    }

    const resetForm = () => {
        reset();
    }

    const uploadImage = async () => {

        // if (imageUrl) {
        //     var splitUrl = imageUrl.split("/")
        //     var lastvalue = splitUrl[splitUrl.length - 1]
        //     var lastValueSplit = lastvalue.split('.')
        //     let response = await putAxiosFormWithToken({
        //         url: s3ImageUploadEndPoint + lastValueSplit[0] +'/' ,
        //         body: new FormData(document.getElementById('imageupload'))
        //     });
    
        //     if (response != null) {
        //         setImageUrl(null)
        //         setImageUrl(response.data)
        //     }
        // } else {
        //     let response = await postAxiosFormWithToken({
        //         url: s3ImageUploadEndPoint,
        //         body: new FormData(document.getElementById('imageupload'))
        //     });
    
        //     if (response != null) {
        //         setImageUrl(null)
        //         setImageUrl(response.data)
        //     }
        // }

        let response = await postAxiosFormWithToken({
            url: s3ImageUploadEndPoint,
            body: new FormData(document.getElementById('imageupload'))
        });

        if (response != null) {
            setImageUrl(null)
            setImageUrl(response.data)
        }
    }

    return (
        <div className=" sm:w-[600px] max-h-[80vh] overflow-y-scroll">
            <form id="imageupload">
                <input hidden onChange={() => uploadImage()} type="file" name="image" id="image" />
            </form>
            <label htmlFor="image" className="bg-orange-300 px-[15px] py-[10px] flex items-center w-fit">Upload Image</label>
            {
                imageUrl && <div className="w-[200px] h-[200px] rounded-full">
                <img src={imageUrl} />
            </div>
            }

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Student Name</p>
                        <input {...register("student_name")} name="student_name" type="text" placeholder="Enter your student name" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.student_name?.message}</p>
                    </div>
                    { formMode == "create" && <div>
                        <p className="text-[14px] mb-[5px] text-primary">Email</p>
                        <input {...register("email")} name="email" type="email" placeholder="Enter your email" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.email?.message}</p>
                    </div> }
                    { formMode == "create" && <div>
                        <p className="text-[14px] mb-[5px] text-primary">Password</p>
                        <input {...register("password")} name="password" type="password" placeholder="Enter your password" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.password?.message}</p>
                    </div> }
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Mobile Number</p>
                        <input {...register("phone_number")} name="phone_number" type="tel" placeholder="Enter your mobile no" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.phone_number?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Designation</p>
                        <input {...register("designation")} name="designation" type="text" placeholder="Enter your designation" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.designation?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Qualification</p>
                        <input {...register("qualification")} name="qualification" type="text" placeholder="Enter your qualification" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.qualification?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Passed Out Year</p>
                        <input {...register("passed_out_year")} name="passed_out_year" type="text" placeholder="Enter your passed out year" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.passed_out_year?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Work Expierence</p>
                        <input {...register("work_expirience")} name="work_expirience" type="text" placeholder="Enter your work expierience" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.work_expirience?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Address</p>
                        <textarea {...register("address")} name="address" type="text" placeholder="Enter your address" className="border rounded p-[7px] w-full text-[14px]"></textarea>
                        <p className="text-[12px] text-red-600">{errors.address?.message}</p>
                    </div>
                </div>

                <div className="flex justify-between items-center gap-3">
                    <button onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }} className="my-[20px] bg-gray-200 border text-secondary text-[14px] w-full rounded p-[5px]">Clear</button>
                    <button type="submit" className="my-[20px] bg-primary text-white text-[14px] w-full rounded p-[5px]">Save</button>
                    {/* <ToastContainer /> */}
                </div>
            </form>
        </div>
    );
}