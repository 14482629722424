import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './sessions/login';
import Layout from './layout';
import { useEffect, useState } from 'react';
import Category from './pages/admin/category';
import Students from './pages/admin/student';
import Trainers from './pages/admin/trainer';
import Course from './pages/admin/course';
import 'react-quill/dist/quill.snow.css';
import Batches from './pages/admin/batches';
import Sessions from './pages/admin/sessions';
import SessionForm from './pages/admin/sessions/session_form';
import CourseForm from './pages/admin/course/course_form';
import Attendence from './pages/students/attendence';
import ExploreCourses from './pages/students/explore_courses';
import MyCourses from './pages/students/my_courses';
import BatchesForm from './pages/admin/batches/batches_form';
import Schedule from './pages/trainer/schedule';
import SessionDetails from './pages/trainer/schedule/session_details';
import NewTask from './pages/trainer/schedule/NewTask';
import Task  from './pages/admin/task';
import Dashboard  from './pages/admin/dashboard';
import DashboardTrainer  from './pages/trainer/dashboard';
import Blog from './pages/admin/blog';
import BlogForm from './pages/admin/blog/blog_form';
function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [role, setRole] = useState(null)
  const [userName, setUserName] = useState(null)

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('login'));

    if (user) {
      setIsAuthenticated(true)

      let userDetails = JSON.parse(localStorage.getItem('login_details'));

      if (userDetails) {
        setRole(userDetails.data.role)
        setUserName(userDetails.data.username)
      } else {
        setRole(null)
        setUserName(null)
      }

    } else {
      setIsAuthenticated(false)
    }
  }, [])

  if (role == "admin") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={!isAuthenticated ? <Login /> : <Layout userName={userName} />}>
            <Route path='/category' element={<Category />} />
            <Route path='/trainer' element={<Trainers />} />
            <Route path='/courses' element={<Course />} />
            <Route path='/courses/course-form' element={<CourseForm />} />
            <Route path='/courses/course-form/:id' element={<CourseForm />} />
            <Route path='/students' element={<Students />} />
            <Route path='/batches' element={<Batches />} />
            <Route path='/batches/batch-form' element={<BatchesForm />} />
            <Route path='/batches/batch-form/:id' element={<BatchesForm />} />
            <Route path='/sessions' element={<Sessions />} />
            <Route path='/sessions/session-form' element={<SessionForm />} />
            <Route path='/sessions/session-form/:id' element={<SessionForm />} />
            <Route path='/task' element={<Task />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/blog/blog-form' element={<BlogForm />} />
            <Route path='/blog/blog-form/:id' element={<BlogForm />} />
            <Route path='/' element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } 
  else if (role == "student") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={!isAuthenticated ? <Login /> : <Layout />}>
            <Route index element={<ExploreCourses />} />
            <Route path='/explore-courses' element={<ExploreCourses />} />
            <Route path='/my-courses' element={<MyCourses />} />
            <Route path='/sessions' element={<p>Sessions</p>} />
            <Route path='/attendence' element={<Attendence />} />
            <Route path='/certificates' element={<p>Certificates</p>} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } 
  else if (role == "trainer") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={!isAuthenticated ? <Login /> : <Layout />}>
            {/* <Route index element={<Schedule />} /> */}
            <Route path='/schedule' element={<Schedule />} />
            <Route path='/Task' element={<NewTask />} />
            <Route path='/schedule-details/:id' element={<SessionDetails />} />
            <Route index element={<DashboardTrainer />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } 
  else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={!isAuthenticated ? <Login /> : <Layout />}>
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
