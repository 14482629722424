import { useEffect, useState } from "react";
import { sessionEndPoint, sessionListEndPoint } from "../../../services/api_endpoints";
import Popup from "../../../components/Popup";
import {ToastContainer, toast } from "react-toastify";
import { deleteAxiosWithToken, postAxiosWithToken } from "../../../services/axios_service";
import Pagination from "../../../components/Pagination";
import { getDateAndTimeText, getDateInText } from "../../../utilities/datetime_utils";
import SessionTableFilter from "./sessions_table_filter";
import { useNavigate } from "react-router-dom";

export default function Sessions() {

    const navigate = useNavigate();

    const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false)

    const [sessionsList, setSessionsList] = useState([]);
    const [currentItem, setCurrentItem] = useState({});

    const [totalPages, setTotalPages] = useState(1);
    const [tableFilters, setTableFilters] = useState({
        search: "",
        page: 1,
        itemsperpage: 10
    })


    useEffect(() => {
        getSessionList()
    }, [tableFilters])

    const getSessionList = async () => {
        let response = await postAxiosWithToken({
            url: sessionListEndPoint,
            body: tableFilters
        });

        if (response != null) {
            setSessionsList(response.data.list)
            setTotalPages(response.data.total_pages)
        }
    }

    const deleteTrainer = async () => {

        let response = await deleteAxiosWithToken({
            url: sessionEndPoint + currentItem.id + "/"
        })

        if (response != null) {
            toast.success(response.message)
            getSessionList()
            setIsDeletePopUpOpen(false)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            itemsperpage: value,
            page: 1,
        }));
    };
    return (
        <div className="w-full bg-white rounded-lg  p-[15px]">
            <div className=" flex justify-between items-center mb-[10px]">
                <p className="text-[12px] sm:text-[16px] sm:tracking-[1px]">Manage Session</p>
                <button onClick={(e) => {
                    e.preventDefault();
                    navigate('session-form')
                }} className="bg-primary text-white text-[12px] sm:text-[16px] w-[75px] sm:w-[100px] h-[30px] sm:h-[40px] rounded font-[500]">Add</button>
            </div>

            <div className="mb-[5px]">
                <SessionTableFilter filters={tableFilters} setFilters={setTableFilters} />
            </div>
            <div className=" overflow-scroll ">
                <table className="w-full">
                    <thead className="bg-primary text-white text-[12px] sm:text-[16px] ">
                        <tr>
                            <th className="p-[10px] text-left min-w-[75px]">S No</th>
                            <th className="p-[10px] text-left min-w-[150px]">Session No</th>
                            <th className="p-[10px] text-left min-w-[150px]">Batch No</th>
                            <th className="p-[10px] text-left min-w-[150px]">Course</th>
                            <th className="p-[10px] text-left min-w-[150px]">Trainer</th>
                            <th className="p-[10px] text-left min-w-[150px]">Start Time</th>
                            <th className="p-[10px] text-left min-w-[150px]">End Time</th>
                            <th className="p-[10px] text-left min-w-[150px]">Created At</th>
                            <th className="p-[10px] text-left min-w-[150px]">Action</th>
                        </tr>
                    </thead>
                    <tbody className="text-[12px] sm:text-[16px] ">
                        {
                            sessionsList.map((item, index) => (
                                <tr className={`${index % 2 == 0 ? "" : "bg-gray-100"}`} key={"trainer" + index}>
                                    <td className="px-[10px] py-[5px] text-left">{(tableFilters.page - 1) * tableFilters.itemsperpage + index + 1}</td>
                                    <td className="px-[10px] py-[5px] text-left">{item.session_no}</td>
                                    <td className="px-[10px] py-[5px] text-left">{item.batch_no}</td>
                                    <td className="px-[10px] py-[5px] text-left">{item.course_details_name}</td>
                                    <td className="px-[10px] py-[5px] text-left">{item.trainer_details_name}</td>
                                    <td className="px-[10px] py-[5px] text-left">{getDateAndTimeText(item.start_time)}</td>
                                    <td className="px-[10px] py-[5px] text-left">{getDateAndTimeText(item.end_time)}</td>
                                    <td className="px-[10px] py-[5px] text-left">{getDateInText(item.created_at)}</td>
                                    <td className="px-[10px] py-[5px] text-left">
                                        <div className="flex">
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate("session-form/"+item.id)
                                                }}
                                                className="rounded w-[35px] h-[35px] text-black flex items-center justify-center"><span className="i-mingcute-edit-4-line w-[25px] h-[25px]"></span></button>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentItem(item)
                                                setIsDeletePopUpOpen(true)
                                            }} className="rounded w-[35px] h-[35px] text-black flex items-center justify-center"><span className="i-mingcute-delete-2-line w-[25px] h-[25px]"></span></button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>


            </div>
            <div className="mt-[5px]">
                <Pagination totalPages={totalPages} currentPage={tableFilters.page} setCurrentPage={pageHandler} entries={tableFilters.itemsperpage} entriesHandler={entriesHandler} />
            </div>

            <Popup isOpen={isDeletePopUpOpen} setIsOpen={() => {
                setIsDeletePopUpOpen(false);
            }}>
                <div className="w-[250px] sm:w-[300px]">
                    <div className="pb-[10px] border-b w-full flex justify-between items-center" >
                        <p >Session Delete</p>
                        <button onClick={(e) => {
                            e.preventDefault();
                            setIsDeletePopUpOpen(false)
                        }}>
                            <span className="i-mingcute-close-line w-[20px] h-[20px]"></span>
                        </button>
                    </div>
                    <p className="text-gray-500 my-[20px]">Are you sure want to delete?</p>
                    <div className="flex justify-between items-center gap-3">
                        <button onClick={(e) => {
                            e.preventDefault();
                            setIsDeletePopUpOpen(false)
                        }} className="my-[20px] bg-gray-200 border text-secondary text-[14px] w-full rounded p-[5px]">No</button>
                        <button onClick={(e) => {
                            e.preventDefault();
                            deleteTrainer()
                        }} className="my-[20px] bg-primary text-white text-[14px] w-full rounded p-[5px]">Yes</button>

{/* <ToastContainer /> */}
                    </div>
                </div>
            </Popup>
        </div>
    );
}