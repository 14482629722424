import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { ToastContainer, toast } from "react-toastify";
import { putAxiosWithToken } from "../../../services/axios_service";
import { changePasswordEndPoint } from "../../../services/api_endpoints";

export default function ChangeStudentPassword({
    currentItem,
    setPopUpOpen,
}) {

    const schema = yup
        .object({
            password: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = async (data) => {

        let response = await putAxiosWithToken({
            url: changePasswordEndPoint + currentItem.user + "/",
            body: data
        })

        if (response != null) {
            toast.success(response.message)
        }
        setPopUpOpen(false);

    }

    const resetForm = () => {
        reset();
    }

    return (
        <div className="sm:w-[300px]">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <p className="text-[14px] mb-[5px] text-primary">Password</p>
                    <input {...register("password")} name="password" type="password" placeholder="Enter your password" className="border rounded p-[7px] w-full text-[14px]" />
                    <p className="text-[12px] text-red-600">{errors.password?.message}</p>
                </div>
                <div className="flex justify-between items-center gap-3">
                    <button onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }} className="my-[20px] bg-gray-200 border text-secondary text-[14px] w-full rounded p-[5px]">Cancel</button>
                    <button type="submit" className="my-[20px] bg-primary text-white text-[14px] w-full rounded p-[5px]">Update Password</button>
                    {/* <ToastContainer /> */}
                </div>
            </form>
        </div>
    );
}