import { categoryListEndPoint, blogEndPoint, s3ImageUploadEndPoint } from "../../../services/api_endpoints";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toast } from "react-toastify";
import { getAxiosWithToken, postAxiosFormWithToken, postAxiosWithToken, putAxiosWithToken } from "../../../services/axios_service";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";


export default function BlogForm() {

    const navigate = useNavigate();

    const { id } = useParams();

    const [formMode, setFormMode] = useState("create");
    const [currentItem, setCurrentItem] = useState({});
    const [imageUrl, setImageUrl] = useState("");
    const [innerText, setInnerText] = useState();
    const [pictureAsFile, setPictureAsFile] = useState(null);

    const schema = yup
        .object({
            blog_title: yup.string().required(),
            short_title: yup.string().required(),
            url: yup.string().required(),
            metal_title: yup.string().required(),
            thumbnail_image: yup.string(),
            created_at: yup.string().required(),
            short_description: yup.string().required(),
            blog_description: yup.string(),
            canonical_url: yup.string(),
            metal_description: yup.string(),
            og_image: yup.string(),
            og_title: yup.string(),
            og_description: yup.string(),
            blog_category_id: yup.string(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm({
        defaultValues: currentItem,
        resolver: yupResolver(schema),
    })

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote',], //'code-block'
        ['link',], // 'image', 'video', 'formula'l

        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        // [{ 'font': [] }],
        [{ 'align': [] }],

        // ['clean']                                         // remove formatting button
    ];

    useEffect(() => {
        if (id) {
            setFormMode("update")
            getblogDetails()
        }
    }, [])

    const getblogDetails = async () => {
        // let response = await getAxiosWithToken({
        //     url: blogEndPoint + '?id=' + id 
        // })
        try {
            const response = await fetch(`https://exam.vantharr.in/scripts/lms_blog_creation.php?action=getBlog&id=${id}`);
            const responseText = await response.text();

            // Remove any invalid prefix like "1test"
            const cleanedResponseText = responseText.replace(/^[^{]+/, '').trim();

            // Parse the cleaned JSON
            const data = JSON.parse(cleanedResponseText);
            const resData = data.data[0];
            if (resData != null) {

                setValue('blog_title', resData.blog_title)
                setValue('short_title', resData.short_title)
                setValue('url', resData.url)
                setValue('created_at', new Date(resData.created_at).toISOString().split('T')[0]);
                setValue('short_description', resData.short_description)
                setValue('canonical_url', resData.canonical_url)
                setValue('metal_title', resData.metal_title)
                setValue('metal_description', resData.metal_description)
                setValue('og_title', resData.og_title)
                setValue('og_description', resData.og_description)
                setValue('blog_category_id', resData.blog_category_id)
                setImageUrl(resData.thumbnail_image)
                setInnerText(resData.blog_description)

            }
            // console.log('Parsed data:', data.data[0]);
        } catch (error) {
            console.error('Error fetching or parsing data:', error);
        }



    }

    const onSubmit = async (data) => {
        const formData = new FormData();
    
        formData.append("blog_title", data.blog_title);
        formData.append("short_description", data.short_description);
        formData.append("url", data.url);
        formData.append("canonical_url", data.canonical_url);
        formData.append("metal_title", data.metal_title);
        formData.append("metal_description", data.metal_description);
        formData.append("og_image", data.og_image);
        formData.append("og_title", data.og_title);
        formData.append("og_description", data.og_description);
        formData.append("blog_description", innerText);
        formData.append("created_at", data.created_at);
        formData.append("created_by", data.created_by || "Admin");
        formData.append("short_title", data.short_title);
        if(formMode === "update") {
        formData.append("id", id);
        }
        if (pictureAsFile) {
            formData.append("thumbnail_image", pictureAsFile);
        }
    
        try {
            const url = `https://exam.vantharr.in/scripts/lms_blog_creation.php`;
            const method = 'POST';
    
            const response = await fetch(url, {
                method,
                body: formData,
            });
    
            const result = await response.json();
            console.log(result);
    
            if (result.success === true) {
                navigate(-1);
                toast.success(result.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(`An error occurred: ${error.message}`);
        }
    };
    


    const resetForm = () => {
        reset();
        setInnerText('')
        setImageUrl('')
    }

    const uploadPicture = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPictureAsFile(file);
            // Create a preview URL for the image
            const previewUrl = URL.createObjectURL(file);
            setImageUrl(previewUrl);
        }
    };

    const uploadImage = async () => {

        // if (imageUrl) {
        //     var splitUrl = imageUrl.split("/")
        //     var lastvalue = splitUrl[splitUrl.length - 1]
        //     var lastValueSplit = lastvalue.split('.')
        //     let response = await putAxiosFormWithToken({
        //         url: s3ImageUploadEndPoint + lastValueSplit[0] +'/' ,
        //         body: new FormData(document.getElementById('imageupload'))
        //     });

        //     if (response != null) {
        //         setImageUrl(null)
        //         setImageUrl(response.data)
        //     }
        // } else {
        //     let response = await postAxiosFormWithToken({
        //         url: s3ImageUploadEndPoint,
        //         body: new FormData(document.getElementById('imageupload'))
        //     });

        //     if (response != null) {
        //         setImageUrl(null)
        //         setImageUrl(response.data)
        //     }
        // }

        let response = await postAxiosFormWithToken({
            url: s3ImageUploadEndPoint,
            body: new FormData(document.getElementById('imageupload'))
        });

        if (response != null) {
            setImageUrl(null)
            setImageUrl(response.data)
        }
    }

    return (
        <div className="">
            <form id="imageupload">{console.log(errors)}
                <input hidden onChange={uploadPicture} type="file" name="image" id="image" />
            </form>
            <label htmlFor="image" className="bg-orange-300 px-[15px] py-[10px] flex items-center w-fit">Upload Image</label>
            {
                imageUrl && (
                    <div className="w-full mb-[15px]">
                        <img src={imageUrl} alt="Thumbnail Preview" className="w-48 h-48 object-cover" />
                    </div>
                )
            }

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="sm:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Title</p>
                        <input {...register("blog_title")} name="blog_title" type="text" placeholder="Enter your title" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.blog_title?.message}</p>
                    </div>
                    {/* <div>
                        <p className="text-[14px] mb-[5px] text-primary">Blog category id</p>
                        <input {...register("blog_category_id")} name="blog_category_id" type="text" placeholder="Enter your blog category  id" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.blog_category_id?.message}</p>
                    </div> */}
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Short Title</p>
                        <input {...register("short_title")} name="short_title" type="text" placeholder="Enter your Short Title" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.short_title?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Published On</p>
                        <input {...register("created_at")} name="created_at" type="date" placeholder="Enter your date" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.created_at?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">URL</p>
                        <input {...register("url")} name="url" type="text" placeholder="Enter your url" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.url?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Canonical URL</p>
                        <input {...register("canonical_url")} name="canonical_url" type="text" placeholder="Enter your Canonical url" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.canonical_url?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Metal Title</p>
                        <input {...register("metal_title")} name="metal_title" type="text" placeholder="Enter your metal title" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.metal_title?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Metal Description</p>
                        <input {...register("metal_description")} name="metal_description" type="text" placeholder="Enter your metal description" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.metal_description?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">OG Image</p>
                        <input type="file" {...register("og_image")} name="og_image" placeholder="" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.og_image?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">OG Title</p>
                        <input type="text" {...register("og_title")} name="og_title" placeholder="Enter your OG title" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.og_title?.message}</p>
                    </div>
                    <div className="col-span-4">
                        <p className="text-[14px] mb-[5px] text-primary">OG Description</p>
                        <textarea {...register("og_description")} name="og_description" type="text" placeholder="Enter your OG description" className="border rounded p-[7px] w-full text-[14px]"></textarea>
                        <p className="text-[12px] text-red-600">{errors.og_description?.message}</p>
                    </div>
                    <div className="col-span-4">
                        <p className="text-[14px] mb-[5px] text-primary">Short Description</p>
                        <textarea {...register("short_description")} name="short_description" type="text" placeholder="Enter your short description" className="border rounded p-[7px] w-full text-[14px]"></textarea>
                        <p className="text-[12px] text-red-600">{errors.short_description?.message}</p>
                    </div>

                    <div className='col-span-4 '>
                        <p className="text-[14px] mb-[5px] text-primary">Full Description</p>
                        <ReactQuill className="bg-white" theme='snow' value={innerText} onChange={(value) => { setInnerText(value) }} modules={{
                            toolbar: toolbarOptions
                        }} />

                    </div>
                </div>

                <div className="flex justify-between items-center gap-3">
                    <button onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }} className="my-[20px] bg-gray-200 border text-secondary text-[14px] w-full rounded p-[5px]">Clear</button>
                    <button type="submit" className="my-[20px] bg-primary text-white text-[14px] w-full rounded p-[5px]">Save</button>
                    {/* <ToastContainer /> */}
                </div>
            </form>
        </div>
    );
}