import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { trainerSttausWithToken } from "../../../services/axios_service";
import { trainerScheduleStatusEndPoint } from "../../../services/api_endpoints";
import { ToastContainer, toast } from "react-toastify";

const SessionStatus = ({ show, onClose, onSave, title, session }) => {
    const [textInput, setTextInput] = useState(session ? session.meet_link : "");
    const [dropdownValue, setDropdownValue] = useState(session ? session.session_type : "");
    const [recordedLink, setRecordedLink] = useState(session ? session.recording_link : "");
    const [Taskedlink, setTasklinke] = useState(session ? session.tast_link : "");
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (session) {
            setDropdownValue(session.session_type);
            setTextInput(session.meet_link);
            setRecordedLink(session.recording_link);
            setTasklinke(session.tast_link);
        }
    }, [session]);

    const capitalizeEachWord = (text) => {
        return text
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const handleSave = async () => {
        setErrors({});

        const isLive = dropdownValue === "live" && !textInput;
        const isRecordingOrCompleted = (dropdownValue === "recording" || dropdownValue === "completed") && !recordedLink;
        const isTaskLinkMissing = !Taskedlink;
        if (isLive) {
            setErrors((prev) => ({ ...prev, textInput: "Meeting Link is required for live sessions." }));
        }

        if (isRecordingOrCompleted) {
            setErrors((prev) => ({ ...prev, recordedLink: "Recorded Link is required for recording/completed sessions." }));
        }
        if (isTaskLinkMissing) {
            setErrors((prev) => ({ ...prev, Taskedlink: "Task Link is required." }));
        }

        if (isLive || isRecordingOrCompleted || isTaskLinkMissing) {
            // console.log(isTaskLinkMissing)
            return;
        }

        const sessionData = {
            session_id: session.session_no,
            session_type: dropdownValue,
            meet_link: textInput ? textInput : '',
            recording_link: recordedLink ? recordedLink : '',
            tast_link: Taskedlink ? Taskedlink : '',
        };
        try {
            const response = await trainerSttausWithToken({
                url: trainerScheduleStatusEndPoint,
                body: sessionData,
            });
           if (response.data && response.data.status) {
            toast.success(response.data.message)
            onClose(); 
          }
        } catch (err) {
            console.log("Saved data:", err);
        }
        resetForm();
    };

    const resetForm = () => {
        setTextInput("");
        setRecordedLink("");
        // settaskLink("");
        setDropdownValue(session ? session.session_type : "");
        setErrors({});
    };

    const renderDropdownOptions = () => {
        switch (dropdownValue) {
            case "upcoming":
                return ["upcoming", "live", "recording"];
            case "live":
                return ["live", "recording", "completed"];
            case "recording":
                return ["live", "recording", "completed"];
            case "completed":
                return ["completed"];
            default:
                return [];
        }
    };

    if (!show) {
        return null;
    }

    return (
        <div style={styles.modalBackdrop} className="z-[2]">
            <div style={styles.modalContent}>
                {session && (
                    <div>
                        <p>Session No: {session.session_no}</p>
                    </div>
                )}
                <div style={{ marginBottom: "20px" }}>
                    <label htmlFor="dropdown">Session Status</label>
                    <select
                        id="dropdown"
                        value={dropdownValue}
                        onChange={(e) => setDropdownValue(e.target.value)}
                        style={{
                            padding: "5px",
                            width: "100%",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: errors.dropdownValue ? "red" : "#ccc",
                            borderRadius: "4px",
                        }}
                    >
                        {renderDropdownOptions().map((option) => (
                            <option key={option} value={option}>{capitalizeEachWord(option)} </option>
                        ))}
                    </select>
                </div>

               { (dropdownValue == 'upcoming' || dropdownValue == 'live') && (
                 <div style={{ marginBottom: "10px" }}>
                 <label htmlFor="textInput">Meeting Link</label>
                 <input
                     type="text"
                     id="textInput"
                     value={textInput}
                     onChange={(e) => setTextInput(e.target.value)}
                     style={{
                         padding: "5px",
                         width: "100%",
                         borderWidth: 1,
                         borderStyle: "solid",
                         borderColor: errors.textInput ? "red" : "#ccc",
                         borderRadius: "4px",
                     }}
                     required={dropdownValue === "live"}
                 />
                 {errors.textInput && <span style={styles.errorMessage}>{errors.textInput}</span>} {/* Display error message */}
             </div>
               )}

               { (dropdownValue == 'completed' || dropdownValue == 'recording') && (  <div style={{ marginBottom: "10px" }}>
                    <label htmlFor="recordedLink">Recorded Link</label>
                    <input
                        type="text"
                        id="recordedLink"
                        value={recordedLink}
                        onChange={(e) => setRecordedLink(e.target.value)}
                        style={{

                            padding: "5px",
                            width: "100%",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: errors.recordedLink ? "red" : "#ccc",
                            borderRadius: "4px",
                        }}
                        required={dropdownValue === "recording" || dropdownValue === "completed"}
                    />
                    {errors.recordedLink && <span style={styles.errorMessage}>{errors.recordedLink}</span>} {/* Display error message */}
                </div>)}
                <div style={{ marginBottom: "10px" }}>
                 <label htmlFor="Taskedlink">Task Link</label>
                 <input
                     type="text"
                     id="Taskedlink"
                     value={Taskedlink}
                     onChange={(e) => setTasklinke(e.target.value)}
                     style={{
                         padding: "5px",
                         width: "100%",
                         borderWidth: 1,
                         borderStyle: "solid",
                         borderColor: errors.Taskedlink ? "red" : "#ccc",
                         borderRadius: "4px",
                     }}
                     required
                 />
                 {errors.Taskedlink && <span style={styles.errorMessage}>{errors.Taskedlink}</span>} 
             </div>
                <div style={styles.modalActions}>
                    <button style={styles.saveButton} onClick={handleSave}>Save</button>
                    {/* <ToastContainer /> */}
                    <button style={styles.cancelButton} onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

// Prop Types for validation
SessionStatus.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    session: PropTypes.shape({
        session_no: PropTypes.string.isRequired,
        session_type: PropTypes.string.isRequired,
    }).isRequired,
};

const styles = {
    modalBackdrop: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modalContent: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        width: "400px",
        textAlign: "left",
    },
    modalActions: { 
        marginTop: "20px",
        textAlign: "right",
        display: 'flex',
        justifyContent:'end'
    },
    saveButton: {
        marginRight: "10px",
        padding: "5px 10px",
        backgroundColor: "#fd7e14",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        marginTop:'10px'
    },
    cancelButton: {
        padding: "5px 10px",
        backgroundColor: "#6c757d",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        marginTop:"10px"
    },
    errorMessage: {
        color: "red",
        fontSize: "12px",
        marginTop: "5px",
    },
};

export default SessionStatus;
