import { Description, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'

export default function Popup({
    isOpen,
    setIsOpen,
    children
}) {

    return (
            <Dialog open={isOpen} onClose={setIsOpen} className="relative z-50 ">
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-black w-screen h-screen bg-opacity-[0.2]">
                    <DialogPanel className=" space-y-4 border bg-white p-5 shadow-lg rounded-lg">
                        {children}
                    </DialogPanel>
                </div>
            </Dialog>
    );
}