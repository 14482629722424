import { useEffect, useState } from "react";
import { siteCourseListEndPoint, studentCourseListEndPoint } from "../../../services/api_endpoints";
import { getAxiosWithToken, postAxiosWithToken } from "../../../services/axios_service";
import Pagination from "../../../components/Pagination";

export default function MyCourses() {

    const [courseList, setCourseList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
        search: "",
        page: 1,
        itemsperpage: 20
    })

    useEffect(() => {
        getCourseList()
    }, [])

    const getCourseList = async () => {
        let response = await postAxiosWithToken({
            url: studentCourseListEndPoint
        });

        if (response != null) {
            setCourseList(response.data.list)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            itemsperpage: value,
            page: 1,
        }));
    };

    return (
        <div className="w-full bg-white rounded-lg  p-[15px]">
            <div className=" flex justify-between items-center mb-[10px]">
                <p className="text-[12px] sm:text-[16px] sm:tracking-[1px]">My Courses</p>
                <button onClick={(e) => {
                    e.preventDefault();
                }} className="bg-primary text-white text-[12px] sm:text-[16px] w-[75px] sm:w-[100px] h-[30px] sm:h-[40px] rounded font-[500]">Contact</button>
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-[25px]">
                {
                    courseList.map((item) => (
                        <div className="border rounded-lg bg-white shadow-xl p-[25px] w-fit">
                            <div className="w-full h-[150px] flex items-center overflow-hidden">
                                <img src={item.thumbnail} />
                            </div>
                            <div className="flex justify-between items-center my-[10px]">
                                <p className="capitalize text-[14px] bg-gray-600 text-white px-[10px] rounded-full py-1">{item.category_name}</p>
                                <p className="capitalize text-[14px] px-[10px]">{item.review} Reviews</p>
                            </div>
                            <p>{item.title}</p>
                        </div>
                    ))
                }
            </div>

            <div className="mt-[5px]">
                <Pagination totalPages={totalPages} currentPage={tableFilters.page} setCurrentPage={pageHandler} entries={tableFilters.itemsperpage} entriesHandler={entriesHandler} />
            </div>
        </div>
    );
}