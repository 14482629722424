export default function TrainersTableFilter({
    filters,
    setFilters
}) {

    const onHandleSearch = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            search: value
        }))
    }
    return (
        <div>
            <p className="text-[14px] mb-[5px] text-primary">Search</p>
            <input value={filters.search} onChange={(e) => {onHandleSearch(e.target.value)}} type='text' placeholder="Search trainers" className="border  rounded p-[7px] w-[250px] text-[14px]" />
        </div>
    );
}