import { categoryListEndPoint, courseEndPoint, s3ImageUploadEndPoint } from "../../../services/api_endpoints";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { ToastContainer,toast } from "react-toastify";
import { getAxiosWithToken, postAxiosFormWithToken, postAxiosWithToken, putAxiosWithToken } from "../../../services/axios_service";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";


export default function CourseForm() {

    const navigate = useNavigate();

    const { id } = useParams();

    const [formMode, setFormMode] = useState("create");
    const [currentItem, setCurrentItem] = useState({});

    const [imageUrl, setImageUrl] = useState("");
    const [innerText, setInnerText] = useState();

    const [categoryList, setCategoryList] = useState([])
    const [selectedCategory, setSelectedCategory] = useState("")
    const [syllabusList, setSyllabusList] = useState([]);

    const [currentSyllabus, setCurrentSyllabus] = useState("");
    const [currentSyllabusIndex, setCurrentSyllabusIndex] = useState(null);
    const [currentSyllabusMode, setCurrentSyllabusMode] = useState("add");

    const schema = yup
        .object({
            category: yup.string().required(),
            title: yup.string().required(),
            review: yup.string().required(),
            thumbnail: yup.string(),
            duration: yup.string().required(),
            syllabus: yup.array().test(
                'syllabus',
                'At least one syllabus topic must be added', // Custom error message
                (value) => syllabusList.length > 0 // Custom validation logic
            ),
            seo_keywords: yup.string().required(),
            seo_title: yup.string().required(),
            seo_description: yup.string().required(),
            short_description: yup.string().required(),
            long_description: yup.string(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm({
        defaultValues: currentItem,
        resolver: yupResolver(schema),
    })

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote',], //'code-block'
        ['link',], // 'image', 'video', 'formula'l
    
        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction
    
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        // [{ 'font': [] }],
        [{ 'align': [] }],
    
        // ['clean']                                         // remove formatting button
      ];

    useEffect(() => {
        getCategoryList()
        if (id) {
            setFormMode("update")
            getCourseDetails()
        }
    },[])

    const getCourseDetails = async () => {
        let response = await getAxiosWithToken({
            url: courseEndPoint + id + "/"
        })

        if (response != null) {


            setValue('title', response.data.title)
            setValue('review', response.data.review)
            setValue('duration', response.data.duration)
            setValue('category', response.data.category)
            setValue('seo_title', response.data.seo_title)
            setValue('seo_keywords', response.data.seo_keywords)
            setValue('seo_description', response.data.seo_description)
            setValue('short_description', response.data.short_description)

            setImageUrl(response.data.thumbnail)
            setSelectedCategory(response.data.category)
            setSyllabusList(response.data.syllabus)
            setInnerText(response.data.long_description)

        }
    }

    const getCategoryList = async () => {
        let response = await getAxiosWithToken({
            url: categoryListEndPoint
        })

        if (response != null) {
            setCategoryList(response.data.list)
        }
    }

    const onSubmit = async (data) => {

        data['thumbnail'] = imageUrl
        data['long_description'] = innerText
        data['syllabus'] = syllabusList


        if (formMode == "create") {
            let response = await postAxiosWithToken({
                url: courseEndPoint,
                body: data
            })

            if (response != null) {
                toast.success(response.message)
                navigate(-1)
                setFormMode("create")
            }
        } else {
            let response = await putAxiosWithToken({
                url: courseEndPoint + id + "/",
                body: data
            })

            if (response != null) {
                navigate(-1)
                toast.success(response.message)
                setFormMode("create")
            }
        }

    }

    const resetForm = () => {
        reset();
        setSelectedCategory("")
    }

    const uploadImage = async () => {

        // if (imageUrl) {
        //     var splitUrl = imageUrl.split("/")
        //     var lastvalue = splitUrl[splitUrl.length - 1]
        //     var lastValueSplit = lastvalue.split('.')
        //     let response = await putAxiosFormWithToken({
        //         url: s3ImageUploadEndPoint + lastValueSplit[0] +'/' ,
        //         body: new FormData(document.getElementById('imageupload'))
        //     });
    
        //     if (response != null) {
        //         setImageUrl(null)
        //         setImageUrl(response.data)
        //     }
        // } else {
        //     let response = await postAxiosFormWithToken({
        //         url: s3ImageUploadEndPoint,
        //         body: new FormData(document.getElementById('imageupload'))
        //     });
    
        //     if (response != null) {
        //         setImageUrl(null)
        //         setImageUrl(response.data)
        //     }
        // }

        let response = await postAxiosFormWithToken({
            url: s3ImageUploadEndPoint,
            body: new FormData(document.getElementById('imageupload'))
        });

        if (response != null) {
            setImageUrl(null)
            setImageUrl(response.data)
        }
    }

    return (
        <div className="">
            <form id="imageupload">{console.log(errors)}
                <input hidden onChange={() => uploadImage()} type="file" name="image" id="image" />
            </form>
            <label htmlFor="image" className="bg-orange-300 px-[15px] py-[10px] flex items-center w-fit">Upload Image</label>
            {
                imageUrl && <div className="w-full mb-[15px]">
                <img src={imageUrl} />
            </div>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="sm:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Title</p>
                        <input {...register("title")} name="title" type="text" placeholder="Enter your title" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.title?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Review</p>
                        <input {...register("review")} name="review" type="text" placeholder="Enter your review" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.review?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Duration</p>
                        <input {...register("duration")} name="duration" type="text" placeholder="Enter your duration" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.duration?.message}</p>
                    </div>
                    <div>
                        <p className="text-[14px] mb-[5px] text-primary">Category</p>
                            
                            <select {...register("category")} onChange={(e) => {
                            setSelectedCategory(e.target.value)
                        }} value={selectedCategory}  name="category" className="border rounded p-[7px] w-full text-[14px] bg-white" >
                            <option value={""}></option>
                            {
                                categoryList.map((item, index) => (
                                    <option value={item.id}>{item.category_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-[12px] text-red-600">{errors.category?.message}</p>
                    </div>
                    <div className="col-span-4">
                        <p className="text-[14px] mb-[5px] text-primary">SEO Title</p>
                        <input {...register("seo_title")} name="seo_title" type="text" placeholder="Enter your seo title" className="border rounded p-[7px] w-full text-[14px]" />
                        <p className="text-[12px] text-red-600">{errors.seo_title?.message}</p>
                    </div>
                    <div className="col-span-4">
                        <p className="text-[14px] mb-[5px] text-primary">SEO Keywords</p>
                        <textarea {...register("seo_keywords")} name="seo_keywords" type="text" placeholder="Enter your seo keywords" className="border rounded p-[7px] w-full text-[14px]"></textarea>
                        <p className="text-[12px] text-red-600">{errors.seo_keywords?.message}</p>
                    </div>
                    <div className="col-span-4">
                        <p className="text-[14px] mb-[5px] text-primary">SEO Description</p>
                        <textarea {...register("seo_description")} name="seo_description" type="text" placeholder="Enter your seo description" className="border rounded p-[7px] w-full text-[14px]"></textarea>
                        <p className="text-[12px] text-red-600">{errors.seo_description?.message}</p>
                    </div>
                    <div className="col-span-4">
                        <p className="text-[14px] mb-[5px] text-primary">Short Description</p>
                        <textarea {...register("short_description")} name="short_description" type="text" placeholder="Enter your short description" className="border rounded p-[7px] w-full text-[14px]"></textarea>
                        <p className="text-[12px] text-red-600">{errors.short_description?.message}</p>
                    </div>
                    <div className="sm:col-span-2 lg:col-span-4">
                        <p className="text-[14px] mb-[5px] text-primary">Syllabus</p>
                        <div className="flex gap-3 ">
                            <input onChange={(e) => { setCurrentSyllabus(e.target.value) }} value={currentSyllabus} name="syllabus" type="text" placeholder="Add here topic name" className="border rounded p-[7px] w-full text-[14px]" />
                            <button onClick={(e) => {
                                e.preventDefault();
                                if (currentSyllabus.length > 0) {
                                    if (currentSyllabusMode == "add") {
                                        setSyllabusList([...syllabusList, currentSyllabus])
                                    } else {
                                        let data = [...syllabusList];
                                        data[currentSyllabusIndex] = currentSyllabus
                                        setSyllabusList(data)
                                    }
                                }
                                setCurrentSyllabus("")
                                setCurrentSyllabusMode('add')
                                setCurrentSyllabusIndex(null)
                            }} className="bg-primary text-white text-[14px] w-[150px] rounded p-[5px] capitalize">{currentSyllabusMode}</button>
                        </div>
                        <p className="text-[12px] text-red-600">{errors.syllabus?.message}</p>
                    </div>
                    <div className=" w-full mt-[10px] sm:col-span-2 lg:col-span-4">
                    {
                        syllabusList.map((item, index) => (
                            <div className="flex gap-3 items-end my-[5px]">
                                <p className="w-full bg-white border p-2">{item}</p>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentSyllabus(item)
                                    setCurrentSyllabusMode("update")
                                    setCurrentSyllabusIndex(index)
                                }} className="bg-gray-200 h-[30px] border text-primary text-[14px] w-[150px] rounded p-[5px]">Update</button>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    let data = [...syllabusList];
                                    data.splice(index, 1)
                                    setSyllabusList(data)
                                }} className="bg-gray-200 h-[30px] border text-primary text-[14px] w-[150px] rounded p-[5px]">Remove</button>
                            </div>
                        ))
                    }
                </div>
                    <div className='col-span-4 '>
                        <p className="text-[14px] mb-[5px] text-primary">Full Description</p>
                        <ReactQuill className="bg-white" theme='snow' value={innerText} onChange={(value) => { setInnerText(value) }} modules={{
                            toolbar: toolbarOptions
                        }} />

                        </div>
                </div>

                <div className="flex justify-between items-center gap-3">
                    <button onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }} className="my-[20px] bg-gray-200 border text-secondary text-[14px] w-full rounded p-[5px]">Clear</button>
                    <button type="submit" className="my-[20px] bg-primary text-white text-[14px] w-full rounded p-[5px]">Save</button>
                    {/* <ToastContainer /> */}
                </div>
            </form>
        </div>
    );
}