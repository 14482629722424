export const baseUrl = "https://api.vantharr.in"

export const loginEndPoint = baseUrl + '/accounts/login/';
export const logoutEndPoint = baseUrl + '/accounts/logout/';
export const changePasswordEndPoint = baseUrl + '/accounts/change-password/';

export const categoryListEndPoint = baseUrl + '/courses/course-category-list/';
export const categoryEndPoint = baseUrl + '/courses/course-category/';

export const studentsListEndPoint = baseUrl + '/accounts/students-list/';
export const studentsEndPoint = baseUrl + '/accounts/students/';

export const trainersListEndPoint = baseUrl + '/accounts/trainers-list/';
export const trainersEndPoint = baseUrl + '/accounts/trainers/';

export const s3ImageUploadEndPoint = baseUrl + "/miscellenous/s3-upload/";

export const courseListEndPoint = baseUrl + '/courses/course-list/';
export const siteCourseListEndPoint = baseUrl + '/courses/site-course-list/';
export const courseEndPoint = baseUrl + '/courses/courses/';

export const blogListEndPoint = 'https://exam.vantharr.in/scripts/lms_blog_creation.php?action=fetch_all';
export const siteblogListEndPoint = baseUrl + '/courses/site-course-list/';
export const blogEndPoint = baseUrl + '/blogs/blog-details/';

export const batchesListEndPoint = baseUrl + '/batches-sessions/batches-list/';
export const batchesEndPoint = baseUrl + '/batches-sessions/batches/';
export const removeStudentFromBatchEndPoint = baseUrl + '/batches-sessions/batch-student-delete/';
export const studentCourseListEndPoint = baseUrl + "/batches-sessions/student-course-list/";

export const sessionListEndPoint = baseUrl + '/batches-sessions/session-list/';
export const sessionEndPoint = baseUrl + '/batches-sessions/sessions/';

export const trainerScheduleListEndPoint = baseUrl + '/batches-sessions/trainer-session-list/';
export const trainerScheduleStatusEndPoint = baseUrl + '/batches-sessions/change-session-status/'; 
export const admintaskEndPoint = baseUrl + '/batches-sessions/task-list/'; 
export const trainertaskEndPoint = baseUrl + '/batches-sessions/trainer-task-list/'; 
export const taskResponseEndPoint = baseUrl + '/batches-sessions/student-task-response/'; 
export const taskPostEndPoint = baseUrl + "/batches-sessions/student-task-response/"
export const taskvalidationEndPoint = baseUrl + "/batches-sessions/task-validation/"
