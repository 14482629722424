import { categoryEndPoint } from "../../../services/api_endpoints";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios";
import {ToastContainer, toast } from "react-toastify";
import { postAxiosWithToken, putAxiosWithToken } from "../../../services/axios_service";

export default function CategoryForm({
    setFormMode,
    formMode,
    fetchData,
    setPopUpOpen,
    currentItem
}) {


    const schema = yup
        .object({
            category_name: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: currentItem,
        resolver: yupResolver(schema),
    })
    const onSubmit = async (data) => {

        if (formMode == "create") {
            let response = await postAxiosWithToken({
                url: categoryEndPoint,
                body: data
            })
            
            if (response != null) {
                toast.success(response.message)
                fetchData()
                setFormMode("create")
            }
        } else {
            let response = await putAxiosWithToken({
                url: categoryEndPoint + currentItem.id + "/",
                body: data
            })
            
            if (response != null) {
                fetchData()
                console.log(response)
                toast.success(response.message)
                setFormMode("create")
            }
        }
        setPopUpOpen(false);
        
    }

    const resetForm = () => {
        reset();
    }

    return (
        <div className=" sm:w-[300px]">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <p className="text-[14px] mb-[5px] text-primary">Category Name</p>
                    <input {...register("category_name")} name="category_name" type="text" placeholder="Enter your category" className="border rounded p-[7px] w-full text-[14px]" />
                    <p className="text-[12px] text-red-600">{errors.category_name?.message}</p>
                </div>

                <div className="flex justify-between items-center gap-3">
                    <button onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }} className="my-[20px] bg-gray-200 border text-secondary text-[14px] w-full rounded p-[5px]">Clear</button>
                    <button type="submit" className="my-[20px] bg-primary text-white text-[14px] w-full rounded p-[5px]">Save</button>
                    {/* <ToastContainer /> */}
                </div>
            </form>
        </div>
    );
}