import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAxiosWithToken } from "../../../services/axios_service";
import { sessionEndPoint } from "../../../services/api_endpoints";
import { getTimeText } from "../../../utilities/datetime_utils"; 

export default function SessionDetails() {

    const navigate = useNavigate();

    const { id } = useParams();

    const [sessionDetails, setSessionDetails] = useState({})
    const [studentAttendenceList, setStudentAttendenceList] = useState([])

    useEffect(() => {
        if (id) {
            getSessionDetails()
        }
    },[])

    const getSessionDetails = async () => {
        let response = await getAxiosWithToken({
            url: sessionEndPoint + id + "/"
        })

        if (response != null) {
            setSessionDetails(response.data)

            let tempList = []

            for (var i of response.data.students) {
                let tempData = i
                tempData['is_present'] = false
                tempList.push(tempData)
            }

            setStudentAttendenceList(tempList)
        }
    }

    const onAttendenceChange = (value, index) => {
        let data = [...studentAttendenceList]
        data[index]['is_present'] = value
        setStudentAttendenceList(data)
    }
    return(
        <div>
            <div>
                <div>
                    <p className="text-[16px] font-medium text-primary my-[5px]">Session No: <span className="font-[400] text-black">{sessionDetails.session_no}</span></p>
                    <p className="text-[16px] font-medium text-primary my-[5px]">Batch No: <span className="font-[400] text-black">{sessionDetails.batch_no}</span></p>
                    <p className="text-[16px] font-medium text-primary my-[5px]">Course: <span className="font-[400] text-black">{sessionDetails.course_title}</span></p>
                    <p className="text-[16px] font-medium text-primary my-[5px]">Session Date: <span className="font-[400] text-black">{sessionDetails.session_date}</span></p>
                    <p className="text-[16px] font-medium text-primary my-[5px]">Start Time: <span className="font-[400] text-black">{getTimeText(sessionDetails?.start_time == undefined ? "" : sessionDetails?.start_time)}</span></p>
                    <p className="text-[16px] font-medium text-primary my-[5px]">End Time: <span className="font-[400] text-black">{getTimeText(sessionDetails?.end_time == undefined ? "" : sessionDetails?.end_time)}</span></p>
                </div>

                <div className=" overflow-scroll ">
                <table className="w-full">
                    <thead className="bg-primary text-white text-[12px] sm:text-[16px] ">
                        <tr>
                            <th className="p-[10px] text-left min-w-[75px]">S No</th>
                            <th className="p-[10px] text-left min-w-[150px]">Name</th>
                            <th className="p-[10px] text-left min-w-[150px]">Phone Number</th>
                            <th className="p-[10px] text-left min-w-[150px]">Preset/Absent</th>
                        </tr>
                    </thead>
                    <tbody className="text-[12px] sm:text-[16px] ">
                        {
                            studentAttendenceList.map((item, index) => (
                                <tr className={`${index % 2 == 0 ? "" : "bg-gray-100"} max-h-[20px] `} key={"trainer" + index}>
                                    <td className="px-[10px] py-[5px] text-left">{index + 1}</td>
                                    <td className="px-[10px] py-[5px] text-left">{item.student_name}</td>
                                    <td className="px-[10px] py-[5px] text-left">{item.student_phone_number}</td>
                                    <td className="px-[10px] py-[5px] text-left">
                                        <input type="checkbox" checked={item.is_present} onChange={(e) => onAttendenceChange(e.target.checked, index)} />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    );
}