import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';


export default function PieCharts({ value,colors }) {
    
const data = [
    { value: 100, color: colors, stroke: 'none' },
  ];

const size = {
  width: 240,
  height: 120,
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}
  return (
    <PieChart series={[{ data, innerRadius: 45, labelPosition: 'none', }]} {...size}>
        <PieCenterLabel>{`${value}`}</PieCenterLabel>
        </PieChart>
  );
}